import crypto from 'crypto-js';

export function encrypt(pwd) {
  
  const secretKey =  'pionic12!@';
	let text = pwd; // 암호화 할 문구
	return crypto.AES.encrypt(text, secretKey).toString();
      
}

export function decrypt(pwd) {
  const secretKey = 'pionic12!@';
  const original = crypto.AES.decrypt(pwd,secretKey)
  return original.toString(crypto.enc.Utf8)
      
}
