import React from 'react';
import {
  Card,
  CardFooter,
  CardBody,
  CardHeader,
  Button,
  Col,
  Modal,
  ModalBody,
  Row,
  Form,
  FormGroup,
} from 'reactstrap';
import { RequestAxios } from '../utils/request';
import Loader from 'react-spinners/RotateLoader';

import logoImg from '../assets/img/puniclogo.png';

import {
  ToastsStore,
  ToastsContainer,
  ToastsContainerPosition,
} from 'react-toasts';
import moment from 'moment';
import { css } from '@emotion/react';
import {
  Mobile,
  getInstagram,
  getYoutube,
  getBlog,
  idSecret,
  emailSecret2,
} from '../utils/Formcheck';
import insta from '../assets/img/headericon/insta.png';
import instaDeactive from '../assets/img/headericon/insta_deactive.png';
import youtube from '../assets/img/headericon/youtube.png';
import youtubeDeactive from '../assets/img/headericon/youtube_deactive.png';
import blog from '../assets/img/headericon/blog.png';
import blogDeactive from '../assets/img/headericon/blog_deactive.png';
import headerImg from '../assets/img/7page_05.jpg';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const linkOpen = juso => {
  window.open(`${juso}`);
};

class FindIdResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      findPopup: false,
      name: '',
      contact: '',
      find: '',
      iconInsta: instaDeactive,
      iconYoutube: youtubeDeactive,
      iconBlog: blogDeactive,
      userInfo: {
        EMAIL: '',
        INSERT_DT: '',
      },
    };

    this.find = this.find.bind(this);
  }

  componentDidMount() {
    if (!this.props.location.state) {
      this.props.history.push('/');
    } else {
      this.setState({
        userInfo: this.props.location.state.userInfo,
      });
      this.props.history.replace('/findidresult', null);
    }
  }

  find = async () => {
    try {
      this.setState({
        loading: true,
      });
      let method = 'get';

      let data = new Map();

      if (this.state.name) {
        data.set('name', this.state.name);
      } else {
        throw '이름을 입력해주세요.';
      }

      if (this.state.contact) {
        data.set('contact', this.state.contact);
      } else {
        throw '연락처를 입력해주세요.';
      }

      let url = `user/findid?name=${this.state.name}&contact=${this.state.contact}`;

      const response = await RequestAxios(data, method, url);

      if (response !== 9999 || response !== 10000) {
        if (response.data.result === 0) {
          this.setState({
            loading: false,
            find: response.data.info.USER_ID,
            findPopup: true,
          });

          ToastsStore.success('아이디 찾기 완료');
        } else if (response.data.result === 3) {
          throw '이미 등록되어 있는 아이디 입니다.';
        } else {
          throw '회원 가입 실패';
        }
      } else {
        if (response === 10000) {
          throw '사용할수 없는 문자열이 포함되어 있습니다.';
        }
      }
    } catch (error) {
      ToastsStore.error(String(error));

      this.setState({
        loading: false,
      });
    }
  };

  render() {
    return (
      <Row
        style={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ToastsContainer
          position={ToastsContainerPosition.BOTTOM_RIGHT}
          store={ToastsStore}
          lightBackground
        />

        <Modal
          key={'loadingModal'}
          isOpen={this.state.loading}
          centered={true}
          className="loading-container"
          fade={false}
        >
          <ModalBody style={{ display: 'none' }}></ModalBody>
          <div className="overlay-box" width="100%">
            <Loader
              color={'#fff'}
              loading={this.state.loading}
              css={override}
              size={15}
              // height={400}
              // width={100}
              // radius={10}
              // margin={20}
              speedMultiplier={0.4}
            />
          </div>
        </Modal>

        <Col lg={12} md={12}>
          <Card style={{ border: 0 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: 10,
                  paddingBottom: 10,
                  borderBottomWidth: 1,
                  borderBottomStyle: 'solid',
                  borderBottomColor: '#dee2e6',
                }}
              >
                <div></div>
                <img
                  src={`${logoImg}`}
                  style={{ height: 40, marginLeft: 80, cursor: 'pointer' }}
                  onClick={() => {
                    this.props.history.push('/');
                  }}
                />
                <div>
                  <img
                    src={this.state.iconInsta}
                    style={{ height: 20, cursor: 'pointer' }}
                    onMouseOver={() => {
                      this.setState({
                        iconInsta: insta,
                      });
                    }}
                    onMouseOut={() => {
                      this.setState({
                        iconInsta: instaDeactive,
                      });
                    }}
                    onClick={() => {
                      linkOpen(getInstagram());
                    }}
                  />
                  <img
                    src={this.state.iconYoutube}
                    style={{ cursor: 'pointer', height: 20, marginLeft: 10 }}
                    onMouseOver={() => {
                      this.setState({
                        iconYoutube: youtube,
                      });
                    }}
                    onMouseOut={() => {
                      this.setState({
                        iconYoutube: youtubeDeactive,
                      });
                    }}
                    onClick={() => {
                      linkOpen(getYoutube());
                    }}
                  />
                  <img
                    src={this.state.iconBlog}
                    style={{
                      height: 20,
                      marginLeft: 10,
                      marginRight: 10,
                      cursor: 'pointer',
                    }}
                    onMouseOver={() => {
                      this.setState({
                        iconBlog: blog,
                      });
                    }}
                    onMouseOut={() => {
                      this.setState({
                        iconBlog: blogDeactive,
                      });
                    }}
                    onClick={() => {
                      linkOpen(getBlog());
                    }}
                  />
                </div>
              </div>

              <div
                style={
                  Mobile()
                    ? { overflowY: 'hidden', height: 200 }
                    : { overflowY: 'hidden', height: 300 }
                }
              >
                <img src={`${headerImg}`} style={{ width: '100%' }} />
              </div>

              <div style={{ width: '90%', maxWidth: 500, marginBottom: 20 }}>
                <CardHeader
                  style={{
                    fontSize: 20,
                    textAlign: 'center',
                    fontWeight: 'bolder',
                    border: 0,
                  }}
                >
                  아이디 찾기 결과
                </CardHeader>
                <CardBody>
                  <Form>
                    <FormGroup>
                      <Col
                        sm={12}
                        style={{
                          borderWidth: 1,
                          borderStyle: 'solid',
                          borderColor: `#eee`,
                          padding: 20,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <h5>ID</h5>
                        <p>
                          {this.state.userInfo.USER_ID &&
                          this.state.userInfo.USER_ID != null
                            ? idSecret(this.state.userInfo.USER_ID)
                            : this.state.userInfo.EMAIL != null
                            ? emailSecret2(this.state.userInfo.EMAIL)
                            : ''}
                        </p>
                        <h5>가입일</h5>
                        <p>
                          {this.state.userInfo.INSERT_DT
                            ? moment(this.state.userInfo.INSERT_DT).format('LL')
                            : ''}
                        </p>
                      </Col>

                      <Col
                        sm={12}
                        style={{
                          padding: 20,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <p>
                          개인정보 보호를 위해 아이디는
                          <br /> 앞 1자리와 끝2자리만 보여지며, 일부 * 로
                          표기됩니다
                        </p>
                      </Col>
                    </FormGroup>

                    <FormGroup
                      style={{
                        width: '100%',
                        paddingLeft: '4%',
                        paddingRight: '4%',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Button
                        color={'primary'}
                        outline
                        onClick={() => {
                          this.props.history.push('/');
                        }}
                      >
                        돌아가기
                      </Button>
                      <Button
                        outline
                        onClick={() => {
                          this.props.history.push('/findpwd');
                        }}
                      >
                        비밀번호 찾기
                      </Button>
                    </FormGroup>
                  </Form>
                </CardBody>
                <CardFooter
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingRight: '7%',
                    fontSize: 11,
                  }}
                >
                  <Col sm={6} style={{ paddingTop: 10 }}>
                    <div>(주)파이오닉</div>
                    <div>Tel. 070-8277-4577</div>
                    <div>평일 10:00 ~ 18:00 상담 가능</div>
                  </Col>

                  <Col sm={6} style={{ paddingTop: 10, textAlign: 'right' }}>
                    <div>사업자등록번호 : 452-86-02106</div>
                    <div>대표자:송승준</div>
                  </Col>
                </CardFooter>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default FindIdResult;
