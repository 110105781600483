import Geocode from 'react-geocode';

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API);
Geocode.setLanguage('ko');
Geocode.setRegion('kr');

export async function getGeocode(address) {
  return Geocode.fromAddress(address)
    .then(response => {
      console.log(response);
      return response.results[0].geometry.location;
    })
    .catch(err => console.log(err));
}

export function getDomain() {
  // return 'https://punic.kr';
  return window.location.origin;
  // return 'http://59.15.209.187';
}

export function getImageDomain() {
  return window.location.origin;
  // return `https://punic.kr`;
  // return 'http://59.15.209.187';
}

export function timeTypeChange(seconds) {
  var hour, min, sec;

  hour = parseInt(seconds / 3600);
  min = parseInt((seconds % 3600) / 60);
  sec = seconds % 60;

  if (hour.toString().length == 1) hour = '0' + hour;
  if (min.toString().length == 1) min = '0' + min;
  if (sec.toString().length == 1) sec = '0' + sec;

  return hour + ':' + min + ':' + sec;
}

export function getInstagram() {
  return 'https://www.instagram.com/pionic_corp/';
}

export function getYoutube() {
  return 'https://www.youtube.com/channel/UCgPy-HyKjjjEtm92sONmPPw';
}

export function getBlog() {
  return 'https://blog.naver.com/pionic_corp';
}

export function Mobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );
}

export function checkMobile() {
  var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

  if (varUA.indexOf('android') > -1) {
    //안드로이드
    return 'android';
  } else if (
    varUA.indexOf('iphone') > -1 ||
    varUA.indexOf('ipad') > -1 ||
    varUA.indexOf('ipod') > -1
  ) {
    //IOS
    return 'ios';
  } else {
    //아이폰, 안드로이드 외
    return 'other';
  }
}

export function telSecret(text) {
  const telArray = text.split('-');
  let secretTel;
  let result;
  result = telArray[1].split('');
  for (var i = 0; i < result.length; i++) {
    result[i] = '*';
  }
  secretTel = result.join('');

  return `${telArray[0]}-${secretTel}-${telArray[2]}`;
}

export function emailSecret2(text) {
  const email = text.split('@')[0];
  const domain = text.split('@')[1];
  let result;
  if (email.length > 3) {
    let originName = email.split('');
    originName.forEach(function (name, i) {
      if (i === 0 || i === originName.length - 1 || i === originName.length - 2)
        return;
      originName[i] = '*';
    });
    var joinName = originName.join();

    result = `${joinName.replace(/,/g, '')}@${domain}`;
  } else {
    var pattern = /.$/; // 정규식
    result = `${email.replace(pattern, '*')}@${domain}`;
  }

  return result;
}

export function idSecret(text) {
  const id = text.split('@')[0];
  let result;
  if (id.length > 3) {
    let originName = id.split('');
    originName.forEach(function (name, i) {
      if (i === 0 || i === originName.length - 1 || i === originName.length - 2)
        return;
      originName[i] = '*';
    });
    var joinName = originName.join();

    result = `${joinName.replace(/,/g, '')}`;
  } else {
    var pattern = /.$/; // 정규식
    result = `${id.replace(pattern, '*')}`;
  }

  return result;
}

export function idSecret2(text) {
  const id = text;
  let result;

  if (id.length > 3) {
    let originName = id.split('');
    originName.forEach(function (name, i) {
      if (i === 0 || i === originName.length - 1 || i === originName.length - 2)
        return;
      originName[i] = '*';
    });
    var joinName = originName.join();

    result = `${joinName.replace(/,/g, '')}`;
  } else {
    var pattern = /.$/; // 정규식
    result = `${id.replace(pattern, '*')}`;
  }

  console.log(result);

  return result;
}

export function fileSize(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function ToDayCheck(curDate) {
  let today, resultDate, curArray, hour, min;
  curArray = curDate.split(' ');
  curDate = curArray[0];
  const time = curArray[1].split(':');
  hour = time[0] * 1;
  min = time[1] * 1;
  today = CurrentDate().split(' ');

  // Time (minutes * seconds * millisecond)
  if (curDate === today[0]) {
    resultDate = `${
      hour < 13
        ? ` 오전 ${hour < 10 ? `0${hour}` : hour}`
        : ` 오후 ${hour < 10 ? `0${hour}` : `${hour - 12}`}`
    }:${min < 10 ? `0${min}` : min}`;
  } else {
    resultDate = curDate;
  }

  return resultDate;
}

export function NumberFormat(_num) {
  let Number = String(_num);
  return Number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
export function userLicenseFormat(num) {
  let ju = num;
  ju = ju.replace('-', '');
  if (ju.length > 6) {
    let ju1 = ju.substring(0, 6);
    let ju2 = ju.substring(6, 13);
    // for (let i = 1; i < ju.substring(6).length && i < 7; i++) {
    //   ju2 = ju2 + '*';
    // }
    return (num = ju1 + '-' + ju2);
  } else {
    return num;
  }
}

export function phoneNumber(num) {
  let formatNum;

  try {
    formatNum = num
      .replace(/[^0-9]/g, '')
      .replace(
        /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,
        '$1-$2-$3',
      )
      .replace('--', '-');
  } catch (e) {
    formatNum = num;
  }

  return formatNum;
}

export function realBirth(year, month, day) {
  const today = new Date();
  const birthDate = new Date(year, Number(month) - 1, day); //월은 1을 빼준다

  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
}

export function birthFormat(num) {
  if (!num) return '';

  let formatNum = '';

  // 공백제거
  num = num.replace(/\s/gi, '');
  num = num.replace(/[^0-9]/g, '');

  if (num.length > 8) {
    this.Show('알림', '생년월일은 8자리만 입력이 가능합니다.', 1, 1);
    num = num.substr(0, 8);
  }

  try {
    if (num.length == 8) {
      formatNum = num.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
    } else if (num.length >= 6 && num.length < 8) {
      formatNum = num.replace(/(\d{4})(\d{2})/, '$1-$2');
    } else if (num.length > 4 && num.length < 6) {
      formatNum = num.replace(/(\d{4})/, '$1-');
    } else {
      formatNum = num;
    }
  } catch (e) {
    formatNum = num;

    console.log(e);
  }

  return formatNum;
}

export function DateChangeConvert(_date) {
  _date = _date.split(' ');
  let first_range_array = _date[0].split('-');
  return (
    first_range_array[0] +
    '.' +
    first_range_array[1] +
    '.' +
    first_range_array[2]
  );
}

export function useLicenseCheck(userLicense) {
  if (userLicense.indexOf('-')) {
    var ssn1 = userLicense.split('-')[0],
      ssn2 = userLicense.split('-')[1],
      ssn = ssn1 + '' + ssn2,
      arr_ssn = [],
      compare = [2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5],
      sum = 0;

    // 입력여부 체크
    if (ssn1 == '') {
      alert('주민등록번호를 기입해주세요.');
      return false;
    }

    if (ssn2 == '') {
      alert('주민등록번호를 기입해주세요.');
      return false;
    }

    // 입력값 체크
    if (ssn1.match('[^0-9]')) {
      alert('주민등록번호는 숫자만 입력하셔야 합니다.');
      return false;
    }
    if (ssn2.match('[^0-9]')) {
      alert('주민등록번호는 숫자만 입력하셔야 합니다.');
      return false;
    }

    // 자리수 체크
    if (ssn.length != 13) {
      alert('올바른 주민등록 번호를 입력하여 주세요.');
      return false;
    }

    // 공식: M = (11 - ((2×A + 3×B + 4×C + 5×D + 6×E + 7×F + 8×G + 9×H + 2×I + 3×J + 4×K + 5×L) % 11)) % 10
    for (var i = 0; i < 13; i++) {
      arr_ssn[i] = ssn.substring(i, i + 1);
    }

    for (var i = 0; i < 12; i++) {
      sum = sum + arr_ssn[i] * compare[i];
    }

    sum = (11 - (sum % 11)) % 10;

    if (sum != arr_ssn[12]) {
      return false;
    }

    return true;
  } else {
    return false;
  }
}

// 영문, 숫자, 특수문자 중 2가지 이상을 포함하여 8~20자로 입력시
export function pwdCheck(Passwd) {
  let result;
  console.log(`Passwd`, Passwd);
  if (
    /^(?!((?:[A-Za-z]+)|(?:[~!@#$%^&*-_+=]+)|(?:[0-9]+))$)[A-Za-z\d~!@#$%^&*-_+=]{8,20}$/.test(
      Passwd,
    )
  ) {
    result = true;
  } else {
    result = false;
  }

  console.log('결과', result);

  return result;
}

export function pwdCheck2(Passwd) {
  let result;
  if (!/^[a-zA-Z0-9]{8,20}$/.test(Passwd)) {
    result = false;
  } else {
    result = true;
  }

  let chk_num = Passwd.search(/[0-9]/g);
  let chk_eng = Passwd.search(/[a-z]/gi);

  if (chk_num < 0 || chk_eng < 0) {
    result = false;
  }

  return result;
}

export function IdCheck(text) {
  let regEmail = /^(?=.*[a-zA-Z])(?=.*[0-9]).{5,25}$/;
  if (regEmail.test(text)) {
    return true;
  } else {
    return false;
  }
}

export function EmailCheck(email) {
  let regEmail =
    /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
  if (regEmail.test(email)) {
    return true;
  } else {
    return false;
  }
}

export function SpecialWordCheck(checkWord) {
  let result;

  if (/[~&*|{}]/.test(checkWord)) {
    result = false;
  } else {
    result = true;
  }

  return result;
}

export function NumberCheck(checkWord) {
  if (/[^0-9]/.test(checkWord)) {
    return false;
  }

  return true;
}

export function CheckSearchedWord(obj) {
  if (obj && obj.length > 0) {
    //특수문자 제거
    // let expText = /[%=]/;
    // if (expText.test(obj) == true) {
    //   return false;
    // }
    //특정문자열(sql예약어의 앞뒤공백포함) 제거
    let sqlArray = new Array(
      //sql 예약어
      'OR',
      'SELECT',
      'INSERT',
      'DELETE',
      'UPDATE',
      'CREATE',
      'DROP',
      'EXEC',
      'UNION',
      'FETCH',
      'DECLARE',
      'TRUNCATE',
    );

    let regex;

    for (let i = 0; i < sqlArray.length; i++) {
      regex = new RegExp(sqlArray[i], 'gi');

      if (regex.test(obj)) {
        if (!SpecialWordCheck(obj)) {
          obj = obj.replace(regex, '');
          return false;
        } else {
          return true;
        }
      }
    }
  }
  return true;
}

export function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  let p = 0.017453292519943295; // Math.PI / 180
  let c = Math.cos;
  let a =
    0.5 -
    c((lat2 - lat1) * p) / 2 +
    (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;

  let result = 12742 * Math.asin(Math.sqrt(a));

  return result.toFixed(1); // 2 * R; R = 6371 km
}

export function DateSplit(_date) {
  _date = _date.split(' ');
  const first_range_array = _date[0].split('-');
  const second_range_array = _date[1].split(':');

  return `${first_range_array[0]},${first_range_array[1]},${first_range_array[2]},${second_range_array[0]},${second_range_array[1]},${second_range_array[2]}`;
}

export function CurrentDate() {
  var date = new Date();
  var year = date.getFullYear().toString();

  var month = date.getMonth() + 1;
  month = month < 10 ? '0' + month.toString() : month.toString();

  var day = date.getDate();
  day = day < 10 ? '0' + day.toString() : day.toString();

  var hour = date.getHours();
  hour = hour < 10 ? '0' + hour.toString() : hour.toString();

  var minites = date.getMinutes();
  minites = minites < 10 ? '0' + minites.toString() : minites.toString();

  var seconds = date.getSeconds();
  seconds = seconds < 10 ? '0' + seconds.toString() : seconds.toString();

  return (
    year + '-' + month + '-' + day + ' ' + hour + ':' + minites + ':' + seconds
  );
}

export function AddLicense(no, licenseLength) {
  let license = '';
  let licenseAddTextLength = parseInt(licenseLength) - no.toString().length;

  for (let i = 0; i < licenseAddTextLength; i++) {
    license += '0';
  }

  return license + no.toString();
}

export function getLevel2(total, target) {
  return total
    .filter(v => {
      return v.M_SIDO == target && v.M_SIDO2 != '';
    })
    .filter((item, index, array) => {
      return array.findIndex(i => i.M_SIDO2 == item.M_SIDO2) == index;
    });
}

export function getLevel3(total, target, target2) {
  console.log(total, target, target2);
  return total.filter(v => {
    return v.M_SIDO == target && v.M_SIDO2 == target2 && v.M_SIDO3 != '';
  });
}

export function getLevel4(total, target, target2, target3) {
  return total.filter(v => {
    return (
      v.M_SIDO == target &&
      v.M_SIDO2 == target2 &&
      v.M_SIDO3 == target3 &&
      v.M_SIDO4 != ''
    );
  });
}
