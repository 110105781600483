import sidebarBgImage from 'assets/img/sidebar/sidebar-4.jpg';
import SourceLink from 'components/SourceLink';
import React from 'react';
// import { FaGithub } from 'react-icons/fa';
import { MdBorderAll, MdKeyboardArrowDown, MdRemove } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import {
  // UncontrolledTooltip,
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
  Modal,
  ModalBody,
} from 'reactstrap';
import bn from 'utils/bemnames';
import Loader from 'react-spinners/RotateLoader';
import { css } from '@emotion/react';
import Cookies from 'universal-cookie';
import { removeSession } from '../../utils/auth';
import ConfirmModal from '../ConfirmModal';
import { disconnectSocket } from '../Socket';
import { adminMenuList } from '../../utils/adminMenu';
import { getInfo } from '../../utils/auth';
// import { BsReverseLayoutSidebarReverse } from 'react-icons/bs';

const cookies = new Cookies();
let role;
let rank;

if (cookies.get('role') !== undefined) {
  role = atob(cookies.get('role')).substring(36, 37);
  rank = atob(cookies.get('role')).substring(37, 38);
} else {
  /*
  인증정보가 없을때 해당 URL이 아닐경우 메인으로 이동
  */

  const userPage = [
    '/findpwd',
    '/findid',
    '/findidresult',
    '/devicefind',
    '/registeragree',
    '/registeragreeresult',
    '/registerauth',
    '/registercomplete',
    '/appauth',
    '/appauthresult',
    '/adminmenuselect',
    '/',
  ];

  if (
    userPage.findIndex(
      element => element === window.location.pathname.toLowerCase(),
    ) === -1
  ) {
    alert('접근 할수 없는 페이지 입니다.');
    window.location.href = '/';
  }
}

const sidebarBackground = {
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

// const navComponents = [
//   { to: '/buttons', name: 'buttons', exact: false, Icon: MdRadioButtonChecked },
//   {
//     to: '/button-groups',
//     name: 'button groups',
//     exact: false,
//     Icon: MdGroupWork,
//   },
//   { to: '/forms', name: 'forms', exact: false, Icon: MdChromeReaderMode },
//   { to: '/input-groups', name: 'input groups', exact: false, Icon: MdViewList },
//   {
//     to: '/dropdowns',
//     name: 'dropdowns',
//     exact: false,
//     Icon: MdArrowDropDownCircle,
//   },
//   { to: '/badges', name: 'badges', exact: false, Icon: MdStar },
//   { to: '/alerts', name: 'alerts', exact: false, Icon: MdNotificationsActive },
//   { to: '/progress', name: 'progress', exact: false, Icon: MdBrush },
//   { to: '/modals', name: 'modals', exact: false, Icon: MdViewDay },
// ];

// const navContents = [
//   { to: '/typography', name: 'typography', exact: false, Icon: MdTextFields },
//   { to: '/tables', name: 'tables', exact: false, Icon: MdBorderAll },
// ];

const pageAdminContents = [
  { to: '/users', name: '사용자', exact: false, Icon: MdRemove },
  // { to: '/admins', name: '관리자', exact: false, Icon: MdRemove },
];

const pageContents = [
  { to: '/notice', name: '공지 사항', exact: false, Icon: MdRemove },
  { to: '/story', name: '퓨닉 스토리', exact: false, Icon: MdRemove },
  { to: '/qna', name: '자주하는 질문', exact: false, Icon: MdRemove },
  { to: '/customer', name: '1:1 문의', exact: false, Icon: MdRemove },
];

const pageAdContents = [
  { to: '/adfile', name: '광고 컨텐츠 관리', exact: false, Icon: MdRemove },
];

const navItems = adminMenuList.single;

// const navItems = [
//   // { to: '/dashboard', name: 'dashboard', exact: true, Icon: MdDashboard },
//   { to: 'device', name: '기기 관리', exact: false, Icon: MdBorderAll },
//   { to: 'deviceinstall', name: '기기 설치', exact: false, Icon: MdBorderAll },
//   { to: 'devicelist', name: '기기 리스트', exact: false, Icon: MdBorderAll },
//   { to: 'devicestatus', name: '기기 상태', exact: false, Icon: MdBorderAll },
//   { to: 'collection', name: '수거 관리', exact: false, Icon: MdBorderAll },
//   { to: 'point', name: '포인트 내역', exact: false, Icon: MdBorderAll },
//   {
//     to: 'exchange',
//     name: '환전 요청/지급 내역',
//     exact: false,
//     Icon: MdBorderAll,
//   },
//   {
//     to: 'donation',
//     name: '후원 요청/지급 내역',
//     exact: false,
//     Icon: MdBorderAll,
//   },
//   { to: 'users', name: '회원 조회', exact: false, Icon: MdBorderAll },
//   { to: 'update', name: '업데이트 관리', exact: false, Icon: MdBorderAll },
//   // { to: '/cards', name: 'cards', exact: false, Icon: MdWeb },
//   // { to: '/charts', name: 'charts', exact: false, Icon: MdInsertChart },
//   // { to: '/widgets', name: 'widgets', exact: false, Icon: MdWidgets },
// ];

// const rank3Items = [
//   // { to: '/dashboard', name: 'dashboard', exact: true, Icon: MdDashboard },
//   { to: '/device', name: '기기 관리', exact: false, Icon: MdBorderAll },
//   { to: '/deviceinstall', name: '기기 설치', exact: false, Icon: MdBorderAll },
//   { to: '/devicelist', name: '기기 리스트', exact: false, Icon: MdBorderAll },
//   { to: '/devicestatus', name: '기기 상태', exact: false, Icon: MdBorderAll },
//   { to: '/update', name: '업데이트 관리', exact: false, Icon: MdBorderAll },
// ];

// const rank2Items = [
//   { to: '/devicelist', name: '기기 리스트', exact: false, Icon: MdBorderAll },
//   { to: '/devicestatus', name: '기기 상태', exact: false, Icon: MdBorderAll },
//   { to: '/collection', name: '수거 관리', exact: false, Icon: MdBorderAll },
// ];

// const rank1Items = [
//   // { to: '/dashboard', name: 'dashboard', exact: true, Icon: MdDashboard },
//   { to: '/devicelist', name: '기기 리스트', exact: false, Icon: MdBorderAll },
//   { to: '/collection', name: '수거 관리', exact: false, Icon: MdBorderAll },
//   { to: '/point', name: '포인트 내역', exact: false, Icon: MdBorderAll },
// ];

const userContents = [
  { to: '/mypoint', name: '내 포인트 정보', exact: false, Icon: MdBorderAll },
  {
    to: '/mybetterycount',
    name: '내 배터리 투입 정보',
    exact: false,
    Icon: MdBorderAll,
  },
  {
    to: '/myrequestdevice',
    name: '내 설치 요청',
    exact: false,
    Icon: MdBorderAll,
  },
  { to: '/mynotice', name: '공지사항', exact: false, Icon: MdBorderAll },
  { to: '/myqna', name: '자주하는 질문', exact: false, Icon: MdBorderAll },
  { to: '/mycustomer', name: '1:1 문의하기', exact: false, Icon: MdBorderAll },
];

const userItems = [
  { to: '/myinfo', name: '내 정보 관리', exact: false, Icon: MdBorderAll },
  { to: '/', name: '로그 아웃', exact: false, Icon: MdBorderAll },
];

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      adminSelect: 1,
      loading: false,
      isOpenPages: false,
      isOpenPages2: false,
      isOpenPages3: false,
      confirmPopup: false,
      userMenu: [],
    };

    this.handleClick = this.handleClick.bind(this);
    this.getUserInfo = this.getUserInfo.bind(this);
  }

  getUserInfo = () => {
    return new Promise(async resolve => {
      const user = await getInfo();
      resolve(user);
    });
  };

  componentDidMount = async () => {
    if (cookies.get('adminSelect')) {
      this.setState({
        adminSelect: cookies.get('adminSelect'),
      });
    }

    const data = await this.getUserInfo();

    if (data) {
      const menuArray = data.MENU ? data.MENU.split(',') : [];

      this.setState({
        userMenu: menuArray,
      });
    }

    let thisLocation = window.location.pathname;
    thisLocation = thisLocation.replace('/', '');

    if (
      thisLocation === 'notice' ||
      thisLocation === 'story' ||
      thisLocation === 'qna' ||
      thisLocation === 'customer'
    ) {
      this.setState({ isOpenPages: true });
    }

    if (thisLocation === 'ad') {
      this.setState({ isOpenPages2: true });
    }

    if (thisLocation === 'users' || thisLocation === 'admins') {
      this.setState({ isOpenPages3: true });
    }

    cookies.addChangeListener(response => {
      console.log(response);
    });
  };

  handleClick = name => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  render() {
    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e('background')} style={sidebarBackground} />
        <div className={bem.e('content')}>
          <Modal
            key={'loadingModal'}
            isOpen={this.state.loading}
            centered={true}
            className="loading-container"
            fade={false}
          >
            <ModalBody style={{ display: 'none' }}></ModalBody>
            <div className="overlay-box" width="100%">
              <Loader
                color={'#fff'}
                loading={this.state.loading}
                css={override}
                size={15}
                speedMultiplier={0.4}
              />
            </div>
          </Modal>
          <ConfirmModal
            open={this.state.confirmPopup}
            fade={false}
            close={() => {
              this.setState({
                confirmPopup: false,
              });
            }}
            confirmOk={() => {
              this.setState({
                loading: true,
              });
              removeSession();
              disconnectSocket();
              setTimeout(() => {
                this.setState({
                  loading: false,
                  confirmPopup: false,
                });
                window.location.href = window.location.origin;
              }, 500);
            }}
            description={'로그아웃 하시겠습니까?'}
          />
          <Navbar>
            <SourceLink className="navbar-brand d-flex">
              <span className="text-white">PUNIC</span>
            </SourceLink>
          </Navbar>
          <Nav vertical>
            {role == 2 &&
            this.state.adminSelect == 2 &&
            rank == 4 &&
            navItems ? (
              navItems.map(({ to, name, exact, Icon }, index) => (
                <NavItem
                  key={index}
                  className={bem.e('nav-item')}
                  onClick={() => {
                    this.setState({ isOpenPages: false, isOpenPages2: false });
                    document
                      .querySelector('.cr-sidebar')
                      .classList.toggle('cr-sidebar--open');
                  }}
                >
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={`/${to}`}
                    activeClassName="active"
                    exact={exact}
                  >
                    {/* <Icon className={bem.e('nav-item-icon')} /> */}
                    <span>{name}</span>
                  </BSNavLink>
                </NavItem>
              ))
            ) : (
              <></>
            )}

            {role == 2 &&
            rank < 3 &&
            this.state.adminSelect == 2 &&
            navItems ? (
              navItems
                .filter(item => {
                  return this.state.userMenu.indexOf(item.to) !== -1;
                })
                .map(({ to, name, exact, Icon }, index) => (
                  <NavItem
                    key={index}
                    className={bem.e('nav-item')}
                    onClick={() => {
                      this.setState({
                        isOpenPages: false,
                        isOpenPages2: false,
                      });
                      document
                        .querySelector('.cr-sidebar')
                        .classList.toggle('cr-sidebar--open');
                    }}
                  >
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      className="text-uppercase"
                      tag={NavLink}
                      to={`/${to}`}
                      activeClassName="active"
                      exact={exact}
                    >
                      {/* <Icon className={bem.e('nav-item-icon')} /> */}
                      <span className="">{name}</span>
                    </BSNavLink>
                  </NavItem>
                ))
            ) : (
              <></>
            )}

            {(role == 1 || this.state.adminSelect == 1) &&
              userContents.map(({ to, name, exact, Icon }, index) => (
                <NavItem
                  key={index}
                  className={bem.e('nav-item')}
                  onClick={() => {
                    this.setState({ isOpenPages: false, isOpenPages2: false });
                    document
                      .querySelector('.cr-sidebar')
                      .classList.toggle('cr-sidebar--open');
                  }}
                >
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    {/* <Icon className={bem.e('nav-item-icon')} /> */}
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}

            {role == 2 &&
            this.state.adminSelect == 2 &&
            (rank == 4 ||
              (rank == 2 && this.state.userMenu.indexOf('customer') !== -1)) ? (
              <NavItem
                className={bem.e('nav-item')}
                onClick={() => {
                  this.setState({
                    isOpenPages2: false,
                    isOpenPages3: false,
                  });
                  this.handleClick('Pages');
                }}
              >
                <BSNavLink className={bem.e('nav-item-collapse')}>
                  <div className="d-flex">
                    {/* <MdPages className={bem.e('nav-item-icon')} /> */}
                    <span className="">고객 지원</span>
                  </div>
                  <MdKeyboardArrowDown
                    className={bem.e('nav-item-icon')}
                    style={{
                      padding: 0,
                      transform: this.state.isOpenPages
                        ? 'rotate(0deg)'
                        : 'rotate(-90deg)',
                      transitionDuration: '0.3s',
                      transitionProperty: 'transform',
                    }}
                  />
                </BSNavLink>
              </NavItem>
            ) : (
              <></>
            )}
            <Collapse
              isOpen={this.state.isOpenPages}
              style={{ backgroundColor: 'rgba(218, 91, 91, 0.5)' }}
            >
              {role == 2 &&
                (rank == 4 ||
                  (rank == 2 &&
                    this.state.userMenu.indexOf('customer') !== -1)) &&
                pageContents.map(({ to, name, exact, Icon }, index) => (
                  <NavItem
                    key={index}
                    className={bem.e('nav-item')}
                    onClick={() => {
                      document
                        .querySelector('.cr-sidebar')
                        .classList.toggle('cr-sidebar--open');
                    }}
                  >
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      className="text-uppercase"
                      tag={NavLink}
                      to={to}
                      activeClassName="active"
                      exact={exact}
                    >
                      <Icon className={bem.e('nav-item-icon')} />
                      <span className="">{name}</span>
                    </BSNavLink>
                  </NavItem>
                ))}
            </Collapse>

            {role == 2 &&
            this.state.adminSelect == 2 &&
            (rank == 4 ||
              (rank == 2 && this.state.userMenu.indexOf('ad') !== -1)) ? (
              <NavItem
                className={bem.e('nav-item')}
                onClick={() => {
                  this.setState({
                    isOpenPages: false,
                    isOpenPages3: false,
                  });
                  this.handleClick('Pages2');
                }}
              >
                <BSNavLink className={bem.e('nav-item-collapse')}>
                  <div className="d-flex">
                    {/* <MdPages className={bem.e('nav-item-icon')} /> */}
                    <span className="">광고 관리</span>
                  </div>
                  <MdKeyboardArrowDown
                    className={bem.e('nav-item-icon')}
                    style={{
                      padding: 0,
                      transform: this.state.isOpenPages2
                        ? 'rotate(0deg)'
                        : 'rotate(-90deg)',
                      transitionDuration: '0.3s',
                      transitionProperty: 'transform',
                    }}
                  />
                </BSNavLink>
              </NavItem>
            ) : (
              <></>
            )}
            <Collapse
              isOpen={this.state.isOpenPages2}
              style={{ backgroundColor: 'rgba(218, 91, 91, 0.5)' }}
            >
              {role == 2 &&
                (rank == 4 ||
                  (rank == 2 && this.state.userMenu.indexOf('ad') !== -1)) &&
                pageAdContents.map(({ to, name, exact, Icon }, index) => (
                  <NavItem
                    key={index}
                    className={bem.e('nav-item')}
                    onClick={() => {
                      document
                        .querySelector('.cr-sidebar')
                        .classList.toggle('cr-sidebar--open');
                    }}
                  >
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      className="text-uppercase"
                      tag={NavLink}
                      to={to}
                      activeClassName="active"
                      exact={exact}
                    >
                      <Icon className={bem.e('nav-item-icon')} />
                      <span className="">{name}</span>
                    </BSNavLink>
                  </NavItem>
                ))}
            </Collapse>

            {userItems.map(({ to, name, exact, Icon }, index) => (
              <NavItem
                key={index}
                className={bem.e('nav-item')}
                onClick={() => {
                  this.setState({ isOpenPages: false, isOpenPages2: false });
                  document
                    .querySelector('.cr-sidebar')
                    .classList.toggle('cr-sidebar--open');
                }}
              >
                {name !== '로그 아웃' ? (
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    exact={exact}
                  >
                    <span className="">{name}</span>
                  </BSNavLink>
                ) : (
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    exact={exact.toString()}
                    onClick={() => {
                      this.setState({
                        confirmPopup: true,
                      });
                    }}
                  >
                    <span className="">{name}</span>
                  </BSNavLink>
                )}
              </NavItem>
            ))}
          </Nav>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
