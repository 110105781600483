import { io } from 'socket.io-client';

// export let socket = io(`${window.location.origin}:6001`, {
//   transports: ['websocket'],
// });
export let socket = io(`wss://punic.kr:6001`, {
  transports: ['websocket'],
});

export const initSocketConnection = () => {
  if (socket.connect === true) return;

  socket.connect();
};

// 이벤트 명을 지정하고 데이터를 보냄
export const sendSocketMessage = (cmd, body = null) => {
  if (socket == null || socket.connected === false) {
    initSocketConnection();
  }

  socket.emit(`${cmd}`, {
    body: body,
  });
};

// 해당 이벤트를 받고 콜백 함수를 실행함
export const socketInfoReceived = () => {
  socket.on('update', res => {
    console.log(res);
  });
};

// 소켓 연결을 끊음
export const disconnectSocket = () => {
  console.log(socket);
  if (socket == null || socket.connected === false) {
    return;
  }
  socket.disconnect();
  // socket = undefined;
};
