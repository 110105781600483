import React from 'react';
import { Card, Col, Row } from 'reactstrap';

import logoImg from '../assets/img/puniclogo.png';

import instaDeactive from '../assets/img/headericon/insta_deactive.png';

import youtubeDeactive from '../assets/img/headericon/youtube_deactive.png';

import blogDeactive from '../assets/img/headericon/blog_deactive.png';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

function getParameterByName(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
    results = regex.exec(window.location.search);
  return results == null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

class RegisterAgreeResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token_version_id: '',
      enc_data: '',
      integrity_value: '',
      yackwanModal: false,
      yackwanTitle: '',
      yackwanDescription: '',
      agress_useterm: false,
      agress_policy: false,
      iconInsta: instaDeactive,
      iconYoutube: youtubeDeactive,
      iconBlog: blogDeactive,
    };
  }

  componentDidMount = () => {
    cookies.set('auth', getParameterByName('enc_data'));
    setTimeout(() => {
      window.close();
    }, 200);

    // thisLocation = thisLocation.replace('/','');
  };

  render() {
    return (
      <Row
        style={{
          height: '85vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Col md={6} lg={4}>
          <Card body>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  width: '98%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <div></div>
                <img
                  src={`${logoImg}`}
                  style={{ height: 40, marginLeft: 80, cursor: 'pointer' }}
                  onClick={() => {
                    this.props.history.push('/');
                  }}
                />
                <div></div>
              </div>
              <div
                style={{
                  marginTop: 30,
                  marginBottom: 30,
                  padding: 20,
                  display: 'flex',
                  justifyContent: 'center',
                  whiteSpace: 'pre-wrap',
                  fontSize: 16,
                }}
              >
                본인인증이 완료 되었습니다.
                <br />
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default RegisterAgreeResult;
