import Cookies from 'universal-cookie';
import { RequestAxios } from './request';
import { decrypt } from './password';
const cookies = new Cookies();

export function setSession(setToken, role, rank) {
  const chars = '123456789';
  const stringLength = 30;
  let randomstring = '';
  for (let i = 0; i < stringLength; i++) {
    const rnum = Math.floor(Math.random() * chars.length);
    randomstring += chars.substring(rnum, rnum + 1);
  }

  let RoleEncrypt = `pionic${randomstring}${role}${rank}`;
  RoleEncrypt = btoa(RoleEncrypt);
  cookies.set('token', setToken, { sameSite: 'strict' });
  cookies.set('role', RoleEncrypt, { sameSite: 'strict' });
}

export async function removeSession() {
  try {
    let userInfo;
    if (cookies.get('token') !== undefined) {
      userInfo = await getInfo();

      let method = 'DELETE';
      let url = 'user/logout';
      let data = new Map();

      data.set('userSq', userInfo.USER_SQ);
      data.set(
        'token',
        cookies.get('token') !== undefined ? cookies.get('token') : '',
      );

      const response = await RequestAxios(data, method, url, false);

      if (response !== 9999 || response !== 10000) {
      } else {
        if (response === 10000) {
          throw '사용할수 없는 문자열이 포함되어 있습니다.';
        }
      }
    }
    cookies.remove('token');
    cookies.remove('role');
    cookies.remove('auth');
    cookies.remove('auths');
  } catch (error) {
    cookies.remove('token');
    cookies.remove('role');
    cookies.remove('auth');
    cookies.remove('auths');
  }
}

export function removeForceSession() {
  cookies.remove('token');
  cookies.remove('role');
  cookies.remove('auth');
  cookies.remove('auths');
}

export async function getInfo() {
  try {
    let method = 'POST';
    let url = 'user/info';
    let data = new Map();

    data.set(
      'token',
      cookies.get('token') !== undefined ? cookies.get('token') : '',
    );

    const response = await RequestAxios(data, method, url, false);

    if (response !== 9999 || response !== 10000) {
      if (response.data.result === 0) {
        if (response.data.data !== undefined) {
          let info = atob(response.data.data);
          info = JSON.parse(decrypt(info.substring(36, info.length)));

          return info;
        }
      } else {
        throw '로그아웃';
      }
    } else {
      if (response === 10000) {
        throw '사용할수 없는 문자열이 포함되어 있습니다.';
      }
    }
  } catch (error) {
    // console.log(error);
  }
}

export async function getUser() {
  try {
    const adminPage = [
      '/device',
      '/deviceinstall',
      '/devicelist',
      '/devicestatus',
      '/collection',
      '/point',
      '/exchange',
      '/donation',
      '/users',
      '/update',
      '/notice',
      '/story',
      '/qna',
      '/customer',
      '/ad',
    ];

    let method = 'POST';
    let url = 'user/info';
    let data = new Map();

    data.set(
      'token',
      cookies.get('token') !== undefined ? cookies.get('token') : '',
    );

    const response = await RequestAxios(data, method, url, false);

    if (response !== 9999 || response !== 10000) {
      if (response.data.result === 0) {
        if (response.data.data !== undefined) {
          let info = atob(response.data.data);
          info = JSON.parse(decrypt(info.substring(36, info.length)));

          if (cookies.get('token')) {
            if (
              info.ROLE === 'ROLE_USER' &&
              adminPage.findIndex(
                element => element === window.location.pathname,
              ) !== -1
            ) {
              alert('접근 할수 없는 페이지 입니다. 메인으로 돌아갑니다.');
              window.location.href = '/main';
            }

            // if (
            //   info.ROLE === 'ROLE_ADMIN' &&
            //   info.LEVEL == 3 &&
            //   rank3Page.findIndex(
            //     element => element === window.location.pathname,
            //   ) === -1
            // ) {
            //   alert('접근 할수 없는 페이지 입니다. 메인으로 돌아갑니다.');
            //   window.location.href = '/main';
            // }

            // if (
            //   info.ROLE === 'ROLE_ADMIN' &&
            //   info.LEVEL == 2 &&
            //   rank2Page.findIndex(
            //     element => element === window.location.pathname,
            //   ) === -1
            // ) {
            //   alert('접근 할수 없는 페이지 입니다. 메인으로 돌아갑니다.');
            //   window.location.href = '/main';
            // }

            // if (
            //   info.ROLE === 'ROLE_ADMIN' &&
            //   info.LEVEL == 1 &&
            //   rank1Page.findIndex(
            //     element => element === window.location.pathname,
            //   ) === -1
            // ) {
            //   alert('접근 할수 없는 페이지 입니다. 메인으로 돌아갑니다.');
            //   window.location.href = '/main';
            // }
          }

          return info;
        }
      } else {
        alert('사용자 정보가 초기화 되어 로그아웃이 됩니다.');
        throw '로그아웃';
      }
    } else {
      if (response === 10000) {
        throw '사용할수 없는 문자열이 포함되어 있습니다.';
      }
    }
  } catch (error) {
    // console.log(error);

    window.location.href = '/';
  }
}
