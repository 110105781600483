import React from 'react';
import {
  Button,
  Modal,
  ModalHeader, 
  ModalBody, 
  ModalFooter,  
} from 'reactstrap';

class ConfirmModal extends React.Component {

  constructor(props) {
    super(props);

  }

  
  render() {
    return (
      
      <Modal
        isOpen={this.props.open}        
        centered={true}
      >
          <ModalHeader style={{backgroundColor:'#eee',borderBottomWidth:0}}>알림</ModalHeader>
          <ModalBody>
          {this.props.description}
          </ModalBody>
          <ModalFooter centered="true" className="justify-content-center" style={{borderTopWidth:0}}>          

            <Button color="primary" outline style={{marginRight:30}} onClick={() => {this.props.confirmOk()}} >확인</Button>

            <Button color="secondary" onClick={() => {
              this.props.close(false)
            }}>취소</Button>
          </ModalFooter>
          
      </Modal>
            
    
    );
  }
}

export default ConfirmModal;