import React from 'react';
import {
  Card,
  CardFooter,
  CardBody,
  CardHeader,
  Button,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Label,
  Input,
  Form,
  FormGroup,
} from 'reactstrap';
import { RequestAxios } from '../utils/request';
import Loader from 'react-spinners/RotateLoader';
import Address from '../components/Address';
import logoImg from '../assets/img/puniclogo.png';

import {
  ToastsStore,
  ToastsContainer,
  ToastsContainerPosition,
} from 'react-toasts';
import crypto from 'crypto';

import Cookies from 'universal-cookie';
import { css } from '@emotion/react';
import {
  EmailCheck,
  pwdCheck,
  phoneNumber,
  getInstagram,
  getYoutube,
  getBlog,
  realBirth,
  IdCheck,
} from '../utils/Formcheck';
import insta from '../assets/img/headericon/insta.png';
import instaDeactive from '../assets/img/headericon/insta_deactive.png';
import youtube from '../assets/img/headericon/youtube.png';
import youtubeDeactive from '../assets/img/headericon/youtube_deactive.png';
import blog from '../assets/img/headericon/blog.png';
import blogDeactive from '../assets/img/headericon/blog_deactive.png';
import { guardian } from '../utils/yackwan';
import { setSession } from '../utils/auth';

const cookies = new Cookies();

const bankList = [
  '신한',
  '카카오뱅크',
  '농협',
  'IBK기업',
  '하나',
  '우리',
  '국민',
  'SC제일',
  '대구',
  '부산',
  '광주',
  '새마을금고',
  '경남',
  '전북',
  '제주',
  '산업',
  '우체국',
  '신협',
  '수협',
  '씨티',
  '케이뱅크',
  '토스뱅크',
  '도이치',
  'BOA',
  'BNP',
  '중국공상',
  'HSBC',
  'JP모간',
  '신림조합',
  '저축은행',
];

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const linkOpen = juso => {
  window.open(`${juso}`);
};

class RegisterAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      addressPopup: false,
      companyAddressPopup: false,
      yackwanModal: false,
      yackwanTitle: '',
      yackwanDescription: '',
      userInfo: {},
      joinType: 1,
      memberList: [],
      id: '',
      idCheck: false,
      email: '',
      eamilAuth: '',
      emailAuthCheck: false,
      emailAuthSend: false,
      pwd: '',
      pwdRe: '',
      name: '',
      nameLock: false,
      child: false,
      guardian: false,
      contact: '',
      contactLock: false,
      bankName: '',
      bankAccount: '',
      bankAccountName: '',
      zipCode: '',
      zipCodeLock: false,
      address: '',
      addressLock: false,
      addressDetail: '',
      companyZipCode: '',
      companyZipCodeLock: false,
      companyAddress: '',
      companyAddressLock: false,
      companyAddressDetail: '',
      companyTel: '',
      companyName: '',
      companyRole: '',
      sido: '',
      sido2: '',
      sido3: '',
      iconInsta: instaDeactive,
      iconYoutube: youtubeDeactive,
      iconBlog: blogDeactive,
    };

    this.Register = this.Register.bind(this);
    this.checkUser = this.checkUser.bind(this);
    this.Login = this.Login.bind(this);
    this.decrypt = this.decrypt.bind(this);
    this.getNiceToken = this.getNiceToken.bind(this);
    this.getNiceSecretToken = this.getNiceSecretToken.bind(this);
    this.formSend = this.formSend.bind(this);
    this.encrypt = this.encrypt.bind(this);
    this.emailAuthSend = this.emailAuthSend.bind(this);
    this.emailAuthCheck = this.emailAuthCheck.bind(this);
    this.idCheck = this.idCheck.bind(this);
  }

  idCheck = async () => {
    try {
      this.setState({
        loading: true,
      });

      if (this.state.id) {
        if (IdCheck(this.state.id)) {
          let method = 'get';
          let url;
          let getInfo;

          url = `user/checkuser?type=3&id=${this.state.id}`;

          let data = new Map();

          const response = await RequestAxios(data, method, url);
          console.log(response);
          if (response !== 9999 || response !== 10000) {
            if (response.data.result == 0) {
              this.setState({
                loading: false,
                idCheck: true,
              });

              ToastsStore.success(`사용 가능한 아이디입니다.`);
            } else if (response.data.result == 2) {
              throw '이미 등록된 아이디 입니다.';
            } else {
              throw '호출 실패';
            }
          } else {
            if (response === 10000) {
              throw '사용할수 없는 문자열이 포함되어 있습니다.';
            }
          }
        } else {
          throw '영문(대소문자 구분), 숫자 포함 5자 이상';
        }
      } else {
        throw '아이디를 입력해주세요.';
      }
    } catch (error) {
      ToastsStore.error(String(error));
      this.setState({
        loading: false,
      });
    }
  };

  emailAuthSend = async () => {
    try {
      this.setState({
        loading: true,
      });
      let method = 'post';
      let url = 'user/mailauth';
      let data = new Map();

      if (this.state.email) {
        data.set('email', this.state.email);
      } else {
        throw '이메일을 입력해주세요.';
      }

      const response = await RequestAxios(data, method, url);

      if (response !== 9999 || response !== 10000) {
        if (response.data.result === 0) {
          ToastsStore.success(
            `${this.state.email}로 전송된 인증번호를 확인해주세요.`,
          );

          this.setState({
            emailAuthSend: true,
            loading: false,
          });
        } else if (response.data.result == 1) {
          throw '이메일 정보가 없습니다.';
        } else if (response.data.result == 2) {
          throw '메일 발신에 실패 하였습니다.';
        } else if (response.data.result == 5) {
          throw '이미 사용중인 메일입니다.';
        } else {
          throw '메일 인증 실패';
        }
      } else {
        if (response === 10000) {
          throw '사용할수 없는 문자열이 포함되어 있습니다.';
        }
      }
    } catch (error) {
      ToastsStore.error(String(error));

      this.setState({
        loading: false,
      });
    }
  };

  emailAuthCheck = async () => {
    try {
      this.setState({
        loading: true,
      });
      let method = 'post';
      let url = 'user/mailauthcheck';
      let data = new Map();

      if (this.state.email) {
        data.set('email', this.state.email);
      } else {
        throw '이메일을 입력해주세요.';
      }

      if (this.state.emailAuth) {
        data.set('authNo', this.state.emailAuth);
      } else {
        throw '인증번호를 입력해주세요.';
      }

      const response = await RequestAxios(data, method, url);

      if (response !== 9999 || response !== 10000) {
        if (response.data.result === 0) {
          ToastsStore.success(`이메일 인증이 완료되었습니다.`);

          this.setState({
            emailAuthCheck: true,
            loading: false,
          });
        } else if (response.data.result == 1) {
          throw '누락된 정보가 있습니다.';
        } else if (response.data.result == 2) {
          throw '인증에 실패 하였습니다.';
        } else if (response.data.result == 5) {
          throw '이미 인증이 끝났거나 없는정보입니다.';
        } else {
          throw '메일 인증 실패';
        }
      } else {
        if (response === 10000) {
          throw '사용할수 없는 문자열이 포함되어 있습니다.';
        }
      }
    } catch (error) {
      ToastsStore.error(String(error));

      this.setState({
        loading: false,
      });
    }
  };

  getNiceToken = async () => {
    try {
      let method = 'GET';
      let url = 'user/getNiceToken';
      let data = new Map();

      const response = await RequestAxios(data, method, url);
      const jsonResponse = JSON.parse(response.data);

      if (
        jsonResponse.dataHeader.GW_RSLT_CD === '1200' &&
        jsonResponse.dataHeader.GW_RSLT_MSG === '오류 없음'
      ) {
        const getBody = jsonResponse.dataBody;

        if (getBody.access_token) {
          this.getNiceSecretToken(getBody.access_token);
        }
      } else {
        console.log('에러');
      }
    } catch (error) {
      ToastsStore.error(String(error));
    }
  };

  encrypt = (data, key, iv) => {
    let cipher = crypto.createCipheriv('aes-128-cbc', key, iv);
    let encrypted = cipher.update(data, 'utf8', 'base64');
    encrypted += cipher.final('base64');
    return encrypted;
  };

  decrypt = data => {
    const key = this.state.sKey;
    const iv = this.state.sIv;

    let decipher = crypto.createDecipheriv('aes-128-cbc', key, iv);
    let decrypted = decipher.update(data, 'base64');
    decrypted += decipher.final();

    return decrypted;
  };

  getNiceSecretToken = async niceToken => {
    try {
      let method = 'GET';
      let url = `user/getNiceSecretToken?niceToken=${niceToken}`;
      let data = new Map();

      const response = await RequestAxios(data, method, url);
      const req_dtim = response.data.req_dtim;
      const req_no = response.data.req_no;

      const jsonResponse = JSON.parse(response.data.data);

      if (
        jsonResponse.dataHeader.GW_RSLT_CD === '1200' &&
        jsonResponse.dataHeader.GW_RSLT_MSG === '오류 없음'
      ) {
        const getBody = jsonResponse.dataBody;

        const token_val = getBody.token_val.trim();

        const token_version_id = getBody.token_version_id;
        const result = `${req_dtim.trim()}${req_no.trim()}${getBody.token_val.trim()}`;

        const resultVal = crypto
          .createHash('sha256')
          .update(result)
          .digest('base64');

        const key = resultVal.substr(0, 16);
        const iv = resultVal.substr(
          resultVal.length - 16,
          resultVal.length - 1,
        );
        const hmac_key = resultVal.substr(0, 32);

        this.setState({
          sIv: iv,
          sKey: key,
        });

        const returnUrl = `${window.location.origin}/registeragreeresult`;

        let plain_data = {
          requestno: req_no,
          returnurl: returnUrl,
          methodtype: 'GET',
          sitecode: getBody.site_code,
          authtype: 'M',
        };

        let plain = JSON.stringify(plain_data);
        let enc_data = this.encrypt(plain, key, iv);

        const hmac = crypto.createHmac('sha256', hmac_key);
        const integrity = hmac.update(enc_data).digest('base64');

        this.setState({
          token_version_id: token_version_id,
          enc_data: enc_data,
          integrity_value: integrity,
        });

        this.formSend();
      } else {
        console.log('에러');
      }
    } catch (error) {
      console.log(error);
    }
  };

  formSend = () => {
    const frm = document.createElement('form');
    frm.setAttribute('id', 'form');
    frm.setAttribute(
      'action',
      'https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb',
    );
    frm.setAttribute('method', 'post');
    frm.setAttribute('target', 'auth-form');

    let hiddenField = document.createElement('input');
    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', 'm');
    hiddenField.setAttribute('value', 'service');
    frm.appendChild(hiddenField);

    hiddenField = document.createElement('input');
    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', 'token_version_id');
    hiddenField.setAttribute('value', this.state.token_version_id);
    frm.appendChild(hiddenField);

    hiddenField = document.createElement('input');
    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', 'enc_data');
    hiddenField.setAttribute('value', this.state.enc_data);
    frm.appendChild(hiddenField);

    hiddenField = document.createElement('input');
    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', 'integrity_value');
    hiddenField.setAttribute('value', this.state.integrity_value);
    frm.appendChild(hiddenField);

    document.body.appendChild(frm);

    window.open(
      'about:blank',
      'auth-form',
      'width=' +
        parseInt(window.innerWidth) * 0.5 +
        ',height=' +
        parseInt(window.innerHeight) * 0.5 +
        ',toolbar=0,menubar=0,location=0,status=0,scrollbars=1,resizable=0,left=0,top=0',
    );
    frm.submit();

    let intervalCookie;

    intervalCookie = setInterval(() => {
      if (cookies.get('auth')) {
        const decryptText = this.decrypt(cookies.get('auth'));

        if (decryptText) {
          const getInfo = JSON.parse(decryptText);
          let child = false;
          let year = Number(getInfo.birthdate.substring(0, 4));
          let month = Number(getInfo.birthdate.substring(4, 6));
          let day = Number(getInfo.birthdate.substring(6, 8));

          if (realBirth(year, month, day) < 14) {
            child = true;
          }

          if (!child) {
            this.setState({
              guardian: true,
            });
          } else {
            ToastsStore.error('보호자의 동의가 필요합니다.');
          }

          cookies.remove('auth');
        }
        clearInterval(intervalCookie);
      }
    }, 1000);
  };

  Login = async () => {
    try {
      this.setState({
        loading: true,
      });
      let method = 'post';
      let url = 'user/auth';
      let data = new Map();

      if (this.state.id) {
        data.set('id', this.state.id);
      } else {
        throw '아이디(이메일)를 입력해주세요.';
      }

      if (this.state.pwd) {
        data.set('pwd', this.state.pwd);
      } else {
        throw '비밀번호를 입력해주세요.';
      }

      const response = await RequestAxios(data, method, url);

      if (response !== 9999 || response !== 10000) {
        if (response.data.result === 0) {
          setSession(response.data.sid, response.data.role);

          this.setState({
            loading: false,
          });

          setTimeout(() => {
            this.props.history.push('/registercomplete');
          }, 500);
        } else if (response.data.result === 3) {
          throw '세션 저장 실패';
        } else if (response.data.result === 5) {
          throw '등록되어있지 않은 아이디 입니다.';
        } else {
          throw '로그인 실패';
        }
      } else {
        if (response === 10000) {
          throw '사용할수 없는 문자열이 포함되어 있습니다.';
        }
      }
    } catch (error) {
      ToastsStore.error(String(error));

      this.setState({
        loading: false,
      });
    }
  };

  Register = async () => {
    try {
      this.setState({
        loading: true,
      });
      let method = 'post';
      let url = 'user/register2';
      let data = new Map();

      if (this.state.child) {
        if (!this.state.guardian) {
          throw '보호자 인증을 먼저 해주세요';
        }
      }

      if (this.state.id) {
        if (this.state.idCheck) {
          if (IdCheck(this.state.id)) {
            data.set('userId', this.state.id);
          } else {
            throw '영문(대소문자 구분), 숫자 포함 5자 이상';
          }
        } else {
          throw '아이디 중복확인을 해주세요.';
        }
      } else {
        throw '아이디를 입력해주세요.';
      }

      if (this.state.emailAuthCheck && this.state.emailAuthSend) {
        if (EmailCheck(this.state.email)) {
          data.set('userEmail', this.state.email);
        } else {
          throw '이메일 형식이 잘못되었습니다..';
        }
      }

      if (this.state.pwd) {
        if (pwdCheck(this.state.pwd)) {
          data.set('pwd', this.state.pwd);
        } else {
          throw '영문, 숫자, 특수문자 중 2가지 이상을 포함하여 8~20자내로 입력해주세요';
        }
      } else {
        throw '비밀번호를 입력해주세요.';
      }

      if (this.state.pwd === this.state.pwdRe) {
        data.set('pwd', this.state.pwd);
      } else {
        throw '비밀번호와 비밀번호 확인이 일치하지 않습니다.';
      }

      if (this.state.name) {
        data.set('name', this.state.name);
      } else {
        throw '이름을 입력해주세요.';
      }

      if (this.state.contact) {
        data.set('contact', this.state.contact);
      } else {
        throw '연락처를 입력해주세요.';
      }

      if (this.state.joinType == 'admin') {
        if (this.state.companyName) {
          data.set('companyName', this.state.companyName);
        } else {
          throw '근무처를 입력해주세요.';
        }

        if (this.state.companyRole) {
          data.set('companyRole', this.state.companyRole);
        } else {
          throw '직급을 입력해주세요.';
        }

        if (this.state.companyTel) {
          data.set('companyTel', this.state.companyTel);
        } else {
          throw '사무실 전화번호를 입력해주세요.';
        }

        if (this.state.companyAddress) {
          data.set('companyAddress', this.state.companyAddress);
        } else {
          throw '직장 주소를 입력해주세요.';
        }

        if (this.state.companyZipCode) {
          data.set('companyZipCode', this.state.companyZipCode);
        }

        if (this.state.companyAddressDetail) {
          data.set('companyAddressDetail', this.state.companyAddressDetail);
        }
      }

      data.set('address', this.state.address);
      data.set('addressDetail', this.state.addressDetail);
      data.set('zipCode', this.state.zipCode);
      data.set(
        'bankName',
        this.state.bankName && this.state.bankName != '은행선택'
          ? this.state.bankName
          : '',
      );
      data.set('bankAccount', this.state.bankAccount);
      data.set('bankAccountName', this.state.bankAccountName);
      data.set('role', 'ROLE_USER');
      data.set('joinType', this.state.joinType == 'admin' ? 2 : 1);
      data.set('level', 1);

      const response = await RequestAxios(data, method, url);

      if (response !== 9999 || response !== 10000) {
        if (response.data.result === 0) {
          this.setState({
            loading: false,
          });

          ToastsStore.success('회원 가입 완료');

          this.Login();
        } else if (response.data.result === 3) {
          throw '이미 등록되어 있는 아이디 입니다.';
        } else {
          throw '회원 가입 실패';
        }
      } else {
        if (response === 10000) {
          throw '사용할수 없는 문자열이 포함되어 있습니다.';
        }
      }
    } catch (error) {
      ToastsStore.error(String(error));

      this.setState({
        loading: false,
      });
    }
  };

  checkUser = async type => {
    try {
      this.setState({
        loading: true,
      });

      let method = 'get';
      let url;
      let getInfo;
      if (type === 1) {
        getInfo = this.state.userInfo;

        url = `user/checkuser?type=${type}&contact=${phoneNumber(
          this.state.userInfo.mobileno,
        )}`;
      } else {
        url = `user/checkuser?type=${type}&id=${this.state.id}`;
      }
      let data = new Map();

      const response = await RequestAxios(data, method, url);

      if (response !== 9999 || response !== 10000) {
        if (response.data.result === 0) {
          this.setState({
            loading: false,
          });

          if (type === 1) {
            if (getInfo) {
              let child = false;
              let year = Number(getInfo.birthdate.substring(0, 4));
              let month = Number(getInfo.birthdate.substring(4, 6));
              let day = Number(getInfo.birthdate.substring(6, 8));

              if (realBirth(year, month, day) < 14) {
                child = true;
              }

              this.setState({
                child: child,
                name: decodeURI(getInfo.utf8_name),
                nameLock: true,
                contact: phoneNumber(getInfo.mobileno),
                contactLock: true,
              });
            } else {
            }
          } else {
            this.Register();
          }
        } else if (response.data.result === 2) {
          throw '이미 등록되어 있는 회원 정보 입니다.';
        } else {
          throw '회원 가입 실패';
        }
      } else {
        if (response === 10000) {
          throw '사용할수 없는 문자열이 포함되어 있습니다.';
        }
      }
    } catch (error) {
      alert(error);

      this.setState({
        loading: false,
      });

      if (type === 1) {
        this.props.history.push('/');
      }
    }
  };

  componentDidMount = () => {
    if (this.props.location.state) {
      this.setState({
        userInfo: this.props.location.state.userInfo,
        joinType: this.props.location.state.joinType,
      });
      setTimeout(() => {
        this.props.history.replace('/registerauth', null);
        this.checkUser(1);
      }, 300);
    } else {
      this.props.history.push('/');
    }
  };

  render() {
    return (
      <Row
        style={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ToastsContainer
          position={ToastsContainerPosition.BOTTOM_RIGHT}
          store={ToastsStore}
          lightBackground
        />

        <Modal
          key={'yackwanModal'}
          isOpen={this.state.yackwanModal}
          centered={true}
          style={{
            minWidth: '90%',
          }}
        >
          <ModalHeader>
            {this.state.yackwanTitle ? this.state.yackwanTitle : ''}
          </ModalHeader>
          <ModalBody className="pionicModalTable">
            <div
              style={{
                height: 'auto',
                minHeight: 300,
                maxHeight: 300,
                overflowY: 'auto',
                padding: 30,
                whiteSpace: 'pre-wrap',
                fontSize: 14,
              }}
            >
              {this.state.yackwanDescription
                ? this.state.yackwanDescription
                : ''}
            </div>
          </ModalBody>
          <ModalFooter
            centered="true"
            className="justify-content-center"
            style={{ borderTopWidth: 0 }}
          >
            <Button
              color="secondary"
              onClick={() => {
                this.setState({ yackwanModal: false });
              }}
            >
              확인
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          key={'loadingModal'}
          isOpen={this.state.loading}
          centered={true}
          className="loading-container"
          fade={false}
        >
          <ModalBody style={{ display: 'none' }}></ModalBody>
          <div className="overlay-box" width="100%">
            <Loader
              color={'#fff'}
              loading={this.state.loading}
              css={override}
              size={15}
              // height={400}
              // width={100}
              // radius={10}
              // margin={20}
              speedMultiplier={0.4}
            />
          </div>
        </Modal>

        {this.state.addressPopup ? (
          <Address
            open={this.state.addressPopup}
            close={closeType =>
              this.setState({
                addressPopup: closeType,
              })
            }
            selectAddress={address => {
              let getAddress;
              let zipCode;

              if (address) {
                getAddress = address.roadAddr ? address.roadAddr : '';
                zipCode = address.zipNo;
              }

              this.setState({
                address: getAddress,
                addressLock: true,
                zipCode: zipCode,
                zipCodeLock: true,
                addressPopup: false,
              });
            }}
          />
        ) : (
          <></>
        )}

        {this.state.companyAddressPopup ? (
          <Address
            open={this.state.companyAddressPopup}
            close={closeType =>
              this.setState({
                companyAddressPopup: closeType,
              })
            }
            selectAddress={address => {
              let getAddress;
              let zipCode;

              if (address) {
                getAddress = address.roadAddr ? address.roadAddr : '';
                zipCode = address.zipNo;
              }

              this.setState({
                companyAddress: getAddress,
                companyAddressLock: true,
                companyZipCode: zipCode,
                companyZipCodeLock: true,
                companyAddressPopup: false,
              });
            }}
          />
        ) : (
          <></>
        )}

        <Col lg={12} md={12}>
          <Card>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: 10,
                  paddingBottom: 10,
                  borderBottomWidth: 1,
                  borderBottomStyle: 'solid',
                  borderBottomColor: '#dee2e6',
                }}
              >
                <div></div>
                <img
                  src={`${logoImg}`}
                  style={{ height: 40, marginLeft: 80, cursor: 'pointer' }}
                  onClick={() => {
                    this.props.history.push('/');
                  }}
                />
                <div>
                  <img
                    src={this.state.iconInsta}
                    style={{ height: 20, cursor: 'pointer' }}
                    onMouseOver={() => {
                      this.setState({
                        iconInsta: insta,
                      });
                    }}
                    onMouseOut={() => {
                      this.setState({
                        iconInsta: instaDeactive,
                      });
                    }}
                    onClick={() => {
                      linkOpen(getInstagram());
                    }}
                  />
                  <img
                    src={this.state.iconYoutube}
                    style={{ cursor: 'pointer', height: 20, marginLeft: 10 }}
                    onMouseOver={() => {
                      this.setState({
                        iconYoutube: youtube,
                      });
                    }}
                    onMouseOut={() => {
                      this.setState({
                        iconYoutube: youtubeDeactive,
                      });
                    }}
                    onClick={() => {
                      linkOpen(getYoutube());
                    }}
                  />
                  <img
                    src={this.state.iconBlog}
                    style={{
                      height: 20,
                      marginLeft: 10,
                      marginRight: 10,
                      cursor: 'pointer',
                    }}
                    onMouseOver={() => {
                      this.setState({
                        iconBlog: blog,
                      });
                    }}
                    onMouseOut={() => {
                      this.setState({
                        iconBlog: blogDeactive,
                      });
                    }}
                    onClick={() => {
                      linkOpen(getBlog());
                    }}
                  />
                </div>
              </div>

              <div style={{ width: '90%', maxWidth: 500, marginBottom: 20 }}>
                <CardHeader
                  style={{ fontSize: 20, textAlign: 'center', borderWidth: 0 }}
                >
                  회원가입
                </CardHeader>
                <CardBody>
                  <Form>
                    <FormGroup>
                      <Label
                        for="exampleEmail"
                        sm={12}
                        style={{ fontWeight: 'bold' }}
                      >
                        아이디 *
                      </Label>
                      <Col
                        sm={12}
                        style={{
                          display: 'flex',
                          gap: 5,
                          fontWeight: 'bold',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Input
                          style={{
                            flex: '0.7',
                          }}
                          type="email"
                          name="id"
                          value={this.state.id}
                          onChange={text => {
                            this.setState({
                              id: text.target.value,
                            });
                          }}
                          placeholder="영문(대소문자 구분), 숫자 포함 5자 이상"
                        />

                        <Button
                          style={{
                            flex: '0.3',
                          }}
                          onClick={() => {
                            this.idCheck();
                          }}
                        >
                          중복확인
                        </Button>
                      </Col>
                    </FormGroup>

                    <FormGroup>
                      <Label
                        for="examplePassword"
                        sm={12}
                        style={{ fontWeight: 'bold' }}
                      >
                        비밀번호 *
                      </Label>
                      <Col sm={12}>
                        <Input
                          type="password"
                          name="pwd"
                          autoComplete="new-password"
                          value={this.state.pwd}
                          onChange={text => {
                            this.setState({
                              pwd: text.target.value,
                            });
                          }}
                          placeholder="영문, 숫자, 특수문자 중 2가지 이상을 포함하여 8~20자내로 입력해주세요."
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        for="examplePassword"
                        sm={12}
                        style={{ fontWeight: 'bold' }}
                      >
                        비밀번호 확인 *
                      </Label>
                      <Col sm={12}>
                        <Input
                          type="password"
                          name="pwdRe"
                          autoComplete="new-password"
                          value={this.state.pwdRe}
                          onChange={text => {
                            this.setState({
                              pwdRe: text.target.value,
                            });
                          }}
                          placeholder="입력하신 비밀번호와 동일하게 입력해주세요."
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup>
                      <Label
                        for="exampleEmail"
                        sm={12}
                        style={{ fontWeight: 'bold' }}
                      >
                        이름 *
                      </Label>
                      <Col sm={12}>
                        <Input
                          type="name"
                          name="name"
                          value={this.state.name}
                          onChange={text => {
                            this.setState({
                              name: text.target.value,
                            });
                          }}
                          placeholder="이름을 입력해주세요."
                          disabled={this.state.nameLock}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup>
                      <Label
                        for="exampleEmail"
                        sm={12}
                        style={{ fontWeight: 'bold' }}
                      >
                        연락처 *
                      </Label>
                      <Col sm={12}>
                        <Input
                          type="tel"
                          name="contact"
                          value={this.state.contact}
                          onChange={text => {
                            this.setState({
                              contact: phoneNumber(text.target.value),
                            });
                          }}
                          placeholder="전화번호를 입력해주세요."
                          disabled={this.state.contactLock}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup>
                      <Label
                        for="exampleEmail"
                        sm={12}
                        style={{ fontWeight: 'bold' }}
                      >
                        이메일
                      </Label>
                      <Col
                        sm={12}
                        style={{
                          display: 'flex',
                          gap: 5,
                          fontWeight: 'bold',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Input
                          style={{
                            flex: '0.7',
                          }}
                          type="email"
                          name="email"
                          value={this.state.email}
                          onChange={text => {
                            this.setState({
                              email: text.target.value,
                            });
                          }}
                          disabled={this.state.emailAuthSend}
                          placeholder="이메일 주소를 입력해주세요."
                        />

                        <Button
                          style={{
                            flex: '0.3',
                          }}
                          onClick={() => {
                            this.emailAuthSend();
                          }}
                          disabled={this.state.emailAuthSend}
                        >
                          인증
                        </Button>
                      </Col>
                      <Col
                        sm={12}
                        style={{
                          marginTop: 5,
                          gap: 5,
                          display: 'flex',
                          fontWeight: 'bold',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Input
                          style={{
                            flex: '0.7',
                          }}
                          type="tel"
                          name="emailauth"
                          value={this.state.emailAuth}
                          disabled={
                            (!this.state.emailAuthSend &&
                              !this.state.emailAuthCheck) ||
                            (this.state.emailAuthSend &&
                              this.state.emailAuthCheck)
                          }
                          onChange={text => {
                            this.setState({
                              emailAuth: text.target.value,
                            });
                          }}
                          placeholder="인증번호를 입력해주세요."
                        />

                        <Button
                          color={'primary'}
                          style={{
                            flex: '0.3',
                          }}
                          onClick={() => {
                            this.emailAuthCheck();
                          }}
                          disabled={
                            (!this.state.emailAuthSend &&
                              !this.state.emailAuthCheck) ||
                            (this.state.emailAuthSend &&
                              this.state.emailAuthCheck)
                          }
                        >
                          인증확인
                        </Button>
                      </Col>
                    </FormGroup>
                    {this.state.joinType == 'admin' ? (
                      <FormGroup>
                        <Label
                          for="exampleEmail"
                          sm={12}
                          style={{ fontWeight: 'bold' }}
                        >
                          근무처 *
                        </Label>
                        <Col sm={12}>
                          <Input
                            type="name"
                            name="companyname"
                            value={this.state.companyName}
                            onChange={text => {
                              this.setState({
                                companyName: text.target.value,
                              });
                            }}
                            placeholder="예) 전라남도 목포시청"
                          />
                        </Col>
                      </FormGroup>
                    ) : (
                      <></>
                    )}

                    {this.state.joinType == 'admin' ? (
                      <FormGroup>
                        <Label
                          for="exampleEmail"
                          sm={12}
                          style={{ fontWeight: 'bold' }}
                        >
                          직급 *
                        </Label>
                        <Col sm={12}>
                          <Input
                            type="name"
                            name="companyrole"
                            value={this.state.companyRole}
                            onChange={text => {
                              this.setState({
                                companyRole: text.target.value,
                              });
                            }}
                            placeholder="예) 주무관"
                          />
                        </Col>
                      </FormGroup>
                    ) : (
                      <></>
                    )}

                    {this.state.joinType == 'admin' ? (
                      <FormGroup>
                        <Label
                          for="exampleEmail"
                          sm={12}
                          style={{ fontWeight: 'bold' }}
                        >
                          사무실 전화번호 *
                        </Label>
                        <Col sm={12}>
                          <Input
                            type="tel"
                            name="companyTel"
                            value={this.state.companyTel}
                            onChange={text => {
                              this.setState({
                                companyTel: phoneNumber(text.target.value),
                              });
                            }}
                            placeholder="직장 사무실 전화번호 입력"
                          />
                        </Col>
                      </FormGroup>
                    ) : (
                      <></>
                    )}

                    {this.state.joinType == 'admin' ? (
                      <FormGroup>
                        <Label
                          for="exampleEmail"
                          sm={12}
                          style={{
                            display: 'flex',
                            fontWeight: 'bold',
                            justifyContent: 'space-between',
                          }}
                        >
                          직장주소*{' '}
                          <Button
                            onClick={() => {
                              this.setState({
                                companyAddressPopup: true,
                              });
                            }}
                          >
                            주소 찾기
                          </Button>
                        </Label>
                        <Col sm={12}>
                          <Input
                            type="text"
                            name="address"
                            value={this.state.companyAddress}
                            onChange={text => {
                              this.setState({
                                companyAddress: text.target.value,
                              });
                            }}
                            disabled={this.state.companyAddressLock}
                            placeholder="주소"
                          />
                        </Col>
                      </FormGroup>
                    ) : (
                      <></>
                    )}

                    {this.state.joinType == 'admin' ? (
                      <FormGroup>
                        <Label
                          for="exampleEmail"
                          sm={12}
                          style={{ fontWeight: 'bold' }}
                        >
                          직장 상세 주소
                        </Label>
                        <Col sm={12}>
                          <Input
                            type="text"
                            name="addressDetail"
                            value={this.state.companyAddressDetail}
                            onChange={text => {
                              this.setState({
                                companyAddressDetail: text.target.value,
                              });
                            }}
                            placeholder="상세 주소"
                          />
                        </Col>
                      </FormGroup>
                    ) : (
                      <></>
                    )}

                    {this.state.joinType == 'admin' ? (
                      <FormGroup>
                        <Label
                          for="exampleEmail"
                          sm={12}
                          style={{ fontWeight: 'bold' }}
                        >
                          직장 우편번호
                        </Label>
                        <Col sm={12}>
                          <Input
                            type="text"
                            name="zipCode"
                            value={this.state.companyZipCode}
                            onChange={text => {
                              this.setState({
                                companyZipCode: text.target.value,
                              });
                            }}
                            disabled={this.state.companyZipCodeLock}
                            placeholder="우편번호"
                          />
                        </Col>
                      </FormGroup>
                    ) : (
                      <></>
                    )}

                    {this.state.child ? (
                      <FormGroup>
                        <Label
                          for="exampleEmail"
                          sm={12}
                          style={{ fontWeight: 'bold' }}
                        >
                          만14세 이하 보호자 인증
                        </Label>
                        <Col
                          sm={12}
                          style={{
                            display: 'flex',
                            fontWeight: 'bold',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Button
                            onClick={() => {
                              this.setState({
                                yackwanModal: true,
                                yackwanTitle: '보호자 동의 약관 보기',
                                yackwanDescription: guardian(),
                              });
                            }}
                            color={'primary'}
                            outline={true}
                          >
                            보호자 동의 약관 보기
                          </Button>
                          <Button
                            onClick={() => {
                              if (!this.state.guardian) {
                                this.getNiceToken();
                              }
                            }}
                            color={!this.state.guardian ? 'danger' : 'success'}
                            outline={!this.state.guardian ? true : false}
                          >
                            {!this.state.guardian ? '인증하기' : '인증완료'}
                          </Button>
                        </Col>
                      </FormGroup>
                    ) : (
                      <></>
                    )}

                    <FormGroup>
                      <Label
                        for="exampleSelect"
                        sm={12}
                        style={{ fontWeight: 'bold' }}
                      >
                        은행선택
                      </Label>
                      <Col sm={12}>
                        <Input
                          type="select"
                          name="bankName"
                          onChange={e => {
                            this.setState({
                              bankName: e.target.value,
                            });
                          }}
                          value={this.state.bankName}
                        >
                          <option key={'은행선택'} value={'은행선택'}>
                            은행선택
                          </option>
                          {bankList &&
                            bankList.map(item => {
                              return (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                        </Input>
                      </Col>
                    </FormGroup>

                    <FormGroup>
                      <Label
                        for="exampleEmail"
                        sm={12}
                        style={{ fontWeight: 'bold' }}
                      >
                        계좌
                      </Label>
                      <Col sm={12}>
                        <Input
                          type="tel"
                          name="bankAccount"
                          value={this.state.bankAccount}
                          onChange={text => {
                            this.setState({
                              bankAccount: text.target.value,
                            });
                          }}
                          placeholder="계좌 번호를 (숫자만) 입력해주세요."
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup>
                      <Label
                        for="exampleEmail"
                        sm={12}
                        style={{ fontWeight: 'bold' }}
                      >
                        예금주명
                      </Label>
                      <Col sm={12}>
                        <Input
                          type="text"
                          name="bankAccountName"
                          value={this.state.bankAccountName}
                          onChange={text => {
                            this.setState({
                              bankAccountName: text.target.value,
                            });
                          }}
                          placeholder="예금주명을 입력해 주세요."
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup>
                      <Label
                        for="exampleEmail"
                        sm={12}
                        style={{
                          display: 'flex',
                          fontWeight: 'bold',
                          justifyContent: 'space-between',
                        }}
                      >
                        주소{' '}
                        <Button
                          onClick={() => {
                            this.setState({
                              addressPopup: true,
                            });
                          }}
                        >
                          주소 찾기
                        </Button>
                      </Label>
                      <Col sm={12}>
                        <Input
                          type="text"
                          name="address"
                          value={this.state.address}
                          onChange={text => {
                            this.setState({
                              address: text.target.value,
                            });
                          }}
                          disabled={this.state.addressLock}
                          placeholder="주소"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        for="exampleEmail"
                        sm={12}
                        style={{ fontWeight: 'bold' }}
                      >
                        상세 주소
                      </Label>
                      <Col sm={12}>
                        <Input
                          type="text"
                          name="addressDetail"
                          value={this.state.addressDetail}
                          onChange={text => {
                            this.setState({
                              addressDetail: text.target.value,
                            });
                          }}
                          placeholder="상세 주소"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        for="exampleEmail"
                        sm={12}
                        style={{ fontWeight: 'bold' }}
                      >
                        우편번호
                      </Label>
                      <Col sm={12}>
                        <Input
                          type="text"
                          name="zipCode"
                          value={this.state.zipCode}
                          onChange={text => {
                            this.setState({
                              zipCode: text.target.value,
                            });
                          }}
                          disabled={this.state.zipCodeLock}
                          placeholder="우편번호"
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: 30,
                      }}
                    >
                      <Button
                        color={'primary'}
                        outline
                        onClick={() => {
                          this.props.history.push('/');
                        }}
                      >
                        돌아가기
                      </Button>
                      <Button
                        outline
                        onClick={() => {
                          this.checkUser(3);
                        }}
                      >
                        회원가입
                      </Button>
                    </FormGroup>
                  </Form>
                </CardBody>
                <CardFooter
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingRight: '7%',
                    fontSize: 11,
                  }}
                >
                  <Col sm={6} style={{ paddingTop: 10 }}>
                    <div>(주)파이오닉</div>
                    <div>Tel. 070-8277-4577</div>
                    <div>평일 10:00 ~ 18:00 상담 가능</div>
                  </Col>

                  <Col sm={6} style={{ paddingTop: 10, textAlign: 'right' }}>
                    <div>사업자등록번호 : 452-86-02106</div>
                    <div>대표자:송승준</div>
                  </Col>
                </CardFooter>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default RegisterAuth;
