import React from 'react';
import { Card, Col, Row, Button } from 'reactstrap';
import mainImg from '../assets/img/5page_02.jpg';
import logoImg from '../assets/img/puniclogo.png';

class RegisterComplete extends React.Component {

  render() {
    return (
      <Row
        style={{
          height: '85vh',
          justifyContent: 'center',
        }}>
        <Col md={12} lg={12}>
          <Card body>
            <div>
              <div style={{display:'flex',marginTop:30,marginBottom:30,justifyContent:'center'}}><img src={`${logoImg}`} style={{height:60, cursor:'pointer'}} onClick={() => {
                this.props.history.push('/')
              }} /></div>
              <div style={{display:'flex',justifyContent:'center', whiteSpace:'pre-wrap'}}><img src={`${mainImg}`} style={{height:300}} /></div>
              <div style={{marginTop:55,padding:20,display:'flex',justifyContent:'center', whiteSpace:'pre-wrap', fontSize:25,fontFamily:'pionic'}}>
                퓨닉 회원가입을 축하 드립니다.<br/>
              </div>
 
            </div>
            <div style={{display:'flex',marginTop:30,marginBottom:30,justifyContent:'center'}}>
            <Button onClick={() => {
                window.location.href='/main'
              }}>메인으로 이동</Button>
            </div>
            
          </Card>
          
        </Col>
      </Row>
    );
  }
}

export default RegisterComplete;
