import { STATE_LOGIN, STATE_SIGNUP } from 'components/AuthForm';
import GAListener from 'components/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import PageSpinner from 'components/PageSpinner';
import AuthPage from 'pages/AuthPage';
import AdminMenuSelect from './pages/AdminMenuSelect';
import ApiPage from './pages/ApiPage';
import RegisterAgree from './pages/RegisterAgree';
import RegisterAuth from './pages/RegisterAuth';
import RegisterComplete from './pages/RegisterComplete';
import RegisterAgreeResult from './pages/RegisterAgreeResult';
import AppAuth from './pages/AppAuth';
import AppAuthResult from './pages/AppAuthResult';
import FindId from './pages/FindId';
import FindIdResult from './pages/FindIdResult';
import FindPwd from './pages/FindPwd';
import ChangePwd from './pages/ChangePwd';
import DeviceFind from './pages/DeviceFind';
import React from 'react';
import { decrypt } from './utils/password';
import { RequestAxios } from './utils/request';
import Cookies from 'universal-cookie';
import componentQueries from 'react-component-queries';
import {
  BrowserRouter,
  Redirect,
  Route,
  Router,
  Switch,
} from 'react-router-dom';
import './styles/reduction.scss';

const cookies = new Cookies();

const Main = React.lazy(() => import('pages/Main'));
const DeviceList = React.lazy(() => import('pages/DeviceList'));
const DeviceListView = React.lazy(() => import('pages/DeviceListView'));
const DeviceInstall = React.lazy(() => import('pages/DeviceInstall'));

const RequestDevice = React.lazy(() => import('pages/RequestDevice'));

const DeviceStatus = React.lazy(() => import('pages/DeviceStatus'));
const CollectionRegister = React.lazy(() => import('pages/CollectionRegister'));
const CollectionList = React.lazy(() => import('pages/CollectionList'));
const CollectionStatus = React.lazy(() => import('pages/CollectionStatus'));
const PointHistory = React.lazy(() => import('pages/PointHistory'));
const Exchange = React.lazy(() => import('pages/Exchange'));
const Donation = React.lazy(() => import('pages/Donation'));
const Users = React.lazy(() => import('pages/Users'));
const Admins = React.lazy(() => import('pages/Admins'));
const Notice = React.lazy(() => import('pages/Notice'));
const Story = React.lazy(() => import('pages/Story'));
const Ad = React.lazy(() => import('pages/Ad'));
const Update = React.lazy(() => import('pages/Update'));
const Qna = React.lazy(() => import('pages/Qna'));
const Customer = React.lazy(() => import('pages/Customer'));
const MyPoint = React.lazy(() => import('pages/MyPoint'));
const MyPointExchangeList = React.lazy(() =>
  import('pages/MyPointExchangeList'),
);
const MyPointDonationList = React.lazy(() =>
  import('pages/MyPointDonationList'),
);
const MyExchange = React.lazy(() => import('pages/MyExchange'));
const MyBetteryCount = React.lazy(() => import('pages/MyBetteryCount'));
const MyNotice = React.lazy(() => import('pages/MyNotice'));
const MyQna = React.lazy(() => import('pages/MyQna'));
const MyCustomer = React.lazy(() => import('pages/MyCustomer'));
const MyRequestDevice = React.lazy(() => import('pages/MyRequestDevice'));
const MyInfo = React.lazy(() => import('pages/MyInfo'));
const ChangeInfo = React.lazy(() => import('pages/ChangeInfo'));
const MyDraw = React.lazy(() => import('pages/MyDraw'));

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

class App extends React.Component {
  // ...

  componentDidUpdate() {
    console.log(this.location.history);
  }

  render() {
    return (
      <BrowserRouter basename={getBasename()}>
        <GAListener>
          <Switch>
            <LayoutRoute
              exact
              path="/"
              layout={EmptyLayout}
              component={props => (
                <AuthPage {...props} authState={STATE_LOGIN} />
              )}
            />

            <LayoutRoute
              exact
              path="/adminmenuselect"
              layout={EmptyLayout}
              component={props => <AdminMenuSelect {...props} />}
            />

            <LayoutRoute
              exact
              path="/registeragree"
              layout={EmptyLayout}
              component={props => <RegisterAgree {...props} />}
            />

            <LayoutRoute
              exact
              path="/registeragreeresult"
              layout={EmptyLayout}
              component={props => <RegisterAgreeResult {...props} />}
            />

            <LayoutRoute
              exact
              path="/registerauth"
              layout={EmptyLayout}
              component={props => <RegisterAuth {...props} />}
            />

            <LayoutRoute
              exact
              path="/registercomplete"
              layout={EmptyLayout}
              component={props => <RegisterComplete {...props} />}
            />

            <LayoutRoute
              exact
              path="/appauth"
              layout={EmptyLayout}
              component={props => <AppAuth {...props} />}
            />

            <LayoutRoute
              exact
              path="/appauthresult"
              layout={EmptyLayout}
              component={props => <AppAuthResult {...props} />}
            />

            <LayoutRoute
              exact
              path="/findid"
              layout={EmptyLayout}
              component={props => <FindId {...props} />}
            />

            <LayoutRoute
              exact
              path="/findidresult"
              layout={EmptyLayout}
              component={props => <FindIdResult {...props} />}
            />

            <LayoutRoute
              exact
              path="/findpwd"
              layout={EmptyLayout}
              component={props => <FindPwd {...props} />}
            />

            <LayoutRoute
              exact
              path="/changepwd"
              layout={EmptyLayout}
              component={props => <ChangePwd {...props} />}
            />

            <LayoutRoute
              exact
              path="/devicefind"
              layout={EmptyLayout}
              component={props => <DeviceFind {...props} />}
            />

            <MainLayout breakpoint={this.props.breakpoint}>
              <React.Suspense fallback={<PageSpinner />}>
                <Route
                  key={'main'}
                  exact
                  path="/main"
                  render={props => <Main {...props} />}
                />
                <Route
                  key={'device'}
                  exact
                  path="/device"
                  render={props => <DeviceList {...props} />}
                />
                <Route
                  key={'deviceinstall'}
                  exact
                  path="/deviceinstall"
                  render={props => <DeviceInstall {...props} />}
                />
                <Route
                  key={'requestdevice'}
                  exact
                  path="/requestdevice"
                  render={props => <RequestDevice {...props} />}
                />

                <Route
                  key={'devicelist'}
                  exact
                  path="/devicelist"
                  render={props => <DeviceListView {...props} />}
                />
                <Route
                  key={'collectionregister'}
                  exact
                  path="/collectionregister"
                  render={props => <CollectionRegister {...props} />}
                />
                <Route
                  key={'collectionlist'}
                  exact
                  path="/collectionlist"
                  render={props => <CollectionList {...props} />}
                />

                <Route
                  key={'devicestatus'}
                  exact
                  path="/devicestatus"
                  render={props => <DeviceStatus {...props} />}
                />
                <Route
                  key={'collection'}
                  exact
                  path="/collection"
                  render={props => <CollectionStatus {...props} />}
                />
                <Route
                  key={'point'}
                  exact
                  path="/point"
                  render={props => <PointHistory {...props} />}
                />
                <Route
                  key={'donation'}
                  exact
                  path="/donation"
                  render={props => <Donation {...props} />}
                />
                <Route
                  key={'exchange'}
                  exact
                  path="/exchange"
                  render={props => <Exchange {...props} />}
                />
                <Route
                  key={'users'}
                  exact
                  path="/users"
                  render={props => <Users {...props} />}
                />
                <Route
                  key={'admins'}
                  exact
                  path="/admins"
                  render={props => <Admins {...props} />}
                />
                <Route
                  key={'adfile'}
                  exact
                  path="/adfile"
                  render={props => <Ad {...props} />}
                />
                <Route
                  key={'update'}
                  exact
                  path="/update"
                  render={props => <Update {...props} />}
                />
                <Route
                  key={'notice'}
                  exact
                  path="/notice"
                  render={props => <Notice {...props} />}
                />
                <Route
                  key={'story'}
                  exact
                  path="/story"
                  render={props => <Story {...props} />}
                />
                <Route
                  key={'qna'}
                  exact
                  path="/qna"
                  render={props => <Qna {...props} />}
                />
                <Route
                  key={'customer'}
                  exact
                  path="/customer"
                  render={props => <Customer {...props} />}
                />
                <Route
                  key={'mypoint'}
                  exact
                  path="/mypoint"
                  render={props => <MyPoint {...props} />}
                />
                <Route
                  key={'mypointexchange'}
                  exact
                  path="/mypointexchange"
                  render={props => <MyPointExchangeList {...props} />}
                />
                <Route
                  key={'mypointdonation'}
                  exact
                  path="/mypointdonation"
                  render={props => <MyPointDonationList {...props} />}
                />
                <Route
                  key={'myexchange'}
                  exact
                  path="/myexchange"
                  render={props => <MyExchange {...props} />}
                />
                <Route
                  key={'mybetterycount'}
                  exact
                  path="/mybetterycount"
                  render={props => <MyBetteryCount {...props} />}
                />
                <Route
                  key={'mynotice'}
                  exact
                  path="/mynotice"
                  render={props => <MyNotice {...props} />}
                />
                <Route
                  key={'myqna'}
                  exact
                  path="/myqna"
                  render={props => <MyQna {...props} />}
                />
                <Route
                  key={'mycustomer'}
                  exact
                  path="/mycustomer"
                  render={props => <MyCustomer {...props} />}
                />

                <Route
                  key={'myrequestdevice'}
                  exact
                  path="/myrequestdevice"
                  render={props => <MyRequestDevice {...props} />}
                />

                <Route
                  key={'myinfo'}
                  exact
                  path="/myinfo"
                  render={props => <MyInfo {...props} />}
                />
                <Route
                  key={'changeinfo'}
                  exact
                  path="/changeinfo"
                  render={props => <ChangeInfo {...props} />}
                />
                <Route
                  key={'mydraw'}
                  exact
                  path="/mydraw"
                  render={props => <MyDraw {...props} />}
                />
              </React.Suspense>
            </MainLayout>
            <Redirect to="/login" />
          </Switch>
        </GAListener>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
