import React from 'react';
import {
  Card,
  CardFooter,
  CardBody,
  CardHeader,
  Button,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Label,
  Input,
  Form,
  FormGroup,
} from 'reactstrap';
import { RequestAxios } from '../utils/request';
import Loader from 'react-spinners/RotateLoader';

import logoImg from '../assets/img/puniclogo.png';

import {
  ToastsStore,
  ToastsContainer,
  ToastsContainerPosition,
} from 'react-toasts';
import { css } from '@emotion/react';
import {
  phoneNumber,
  Mobile,
  getInstagram,
  getYoutube,
  getBlog,
} from '../utils/Formcheck';
import insta from '../assets/img/headericon/insta.png';
import instaDeactive from '../assets/img/headericon/insta_deactive.png';
import youtube from '../assets/img/headericon/youtube.png';
import youtubeDeactive from '../assets/img/headericon/youtube_deactive.png';
import blog from '../assets/img/headericon/blog.png';
import blogDeactive from '../assets/img/headericon/blog_deactive.png';
import headerImg from '../assets/img/7page_05.jpg';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const linkOpen = juso => {
  window.open(`${juso}`);
};

class FindId extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      findPopup: false,
      popupMsg: '',
      name: '',
      contact: '',
      authNo: '',
      find: '',
      authCheckBtn: false,
      authResult: false,
      findBtn: false,
      iconInsta: instaDeactive,
      iconYoutube: youtubeDeactive,
      iconBlog: blogDeactive,
    };

    this.find = this.find.bind(this);
    this.auth = this.auth.bind(this);
    this.authCheck = this.authCheck.bind(this);
  }

  auth = async () => {
    try {
      this.setState({
        loading: true,
      });
      let method = 'POST';
      let url = `user/smsauth`;
      let data = new Map();

      if (this.state.contact) {
        data.set('contact', this.state.contact);
      } else {
        throw '연락처를 입력해주세요.';
      }

      const response = await RequestAxios(data, method, url);

      if (response !== 9999 || response !== 10000) {
        console.log(response.data.result);
        if (response.data.result === 0) {
          this.setState({
            loading: false,
            authCheckBtn: true,
          });

          ToastsStore.success(
            '문자로 전송된 인증 문자를 입력해 인증 확인을 해주세요.',
          );
        } else if (response.data.result === 2) {
          throw '서버 에러';
        } else if (response.data.result === 3) {
          throw '인증 문자 전송 실패';
        } else if (response.data.result === 5) {
          throw '연락처와 매칭된 회원 정보가 없습니다.';
        } else {
          throw '서버 에러 잠시후 다시 시도해 주세요.';
        }
      } else {
        if (response === 10000) {
          throw '사용할수 없는 문자열이 포함되어 있습니다.';
        }
      }
    } catch (error) {
      this.setState({
        loading: false,
        popupMsg: String(error),
        findPopup: true,
      });
    }
  };

  authCheck = async () => {
    try {
      this.setState({
        loading: true,
      });
      let method = 'POST';
      let url = `user/smsauthcheck`;
      let data = new Map();

      if (this.state.contact) {
        data.set('contact', this.state.contact);
      } else {
        throw '연락처를 입력해주세요.';
      }

      if (this.state.authNo) {
        data.set('authNo', this.state.authNo);
      } else {
        throw '인증 번호를 다시 확인해주세요.';
      }

      const response = await RequestAxios(data, method, url);

      if (response !== 9999 || response !== 10000) {
        if (response.data.result === 0) {
          this.setState({
            loading: false,
            authResult: true,
          });

          this.find();
        } else if (response.data.result === 5) {
          throw '인증 번호가 일치하지 않습니다.';
        } else {
          throw '서버 에러 잠시후 다시 시도해 주세요.';
        }
      } else {
        if (response === 10000) {
          throw '사용할수 없는 문자열이 포함되어 있습니다.';
        }
      }
    } catch (error) {
      this.setState({
        loading: false,
        popupMsg: String(error),
        findPopup: true,
      });
    }
  };

  find = async () => {
    try {
      this.setState({
        loading: true,
      });
      let method = 'get';

      let data = new Map();

      if (this.state.name) {
        data.set('name', this.state.name);
      } else {
        throw '이름을 입력해주세요.';
      }

      if (this.state.contact) {
        data.set('contact', this.state.contact);
      } else {
        throw '연락처를 입력해주세요.';
      }

      let url = `user/findid?name=${this.state.name}&contact=${this.state.contact}`;

      const response = await RequestAxios(data, method, url);

      if (response !== 9999 || response !== 10000) {
        if (response.data.result === 0) {
          this.setState({
            loading: false,
            find: response.data.info.EMAIL,
          });

          this.props.history.push({
            pathname: '/findidresult',
            state: { userInfo: response.data.info },
          });
          ToastsStore.success('아이디 찾기 완료');
        } else if (response.data.result === 5) {
          throw '등록된 휴대폰 번호,이름과 \n 일치하는 정보가 없습니다.';
        } else {
          throw '회원 가입 실패';
        }
      } else {
        if (response === 10000) {
          throw '사용할수 없는 문자열이 포함되어 있습니다.';
        }
      }
    } catch (error) {
      this.setState({
        loading: false,
        popupMsg: String(error),
        findPopup: true,
      });
    }
  };

  render() {
    return (
      <Row
        style={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ToastsContainer
          position={ToastsContainerPosition.BOTTOM_RIGHT}
          store={ToastsStore}
          lightBackground
        />

        <Modal
          key={'loadingModal'}
          isOpen={this.state.loading}
          centered={true}
          className="loading-container"
          fade={false}
        >
          <ModalBody style={{ display: 'none' }}></ModalBody>
          <div className="overlay-box" width="100%">
            <Loader
              color={'#fff'}
              loading={this.state.loading}
              css={override}
              size={15}
              // height={400}
              // width={100}
              // radius={10}
              // margin={20}
              speedMultiplier={0.4}
            />
          </div>
        </Modal>

        <Modal
          key={'messagePopup'}
          isOpen={this.state.findPopup}
          centered={true}
          className="loading-container"
        >
          <ModalHeader>알림</ModalHeader>
          <ModalBody>{this.state.popupMsg}</ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                this.setState({
                  findPopup: false,
                });
              }}
            >
              확인
            </Button>
          </ModalFooter>
        </Modal>

        <Col lg={12} md={12}>
          <Card style={{ border: 0 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: 10,
                  paddingBottom: 10,
                  borderBottomWidth: 1,
                  borderBottomStyle: 'solid',
                  borderBottomColor: '#dee2e6',
                }}
              >
                <div></div>
                <img
                  src={`${logoImg}`}
                  style={{ height: 40, marginLeft: 80, cursor: 'pointer' }}
                  onClick={() => {
                    this.props.history.push('/');
                  }}
                />
                <div>
                  <img
                    src={this.state.iconInsta}
                    style={{ height: 20, cursor: 'pointer' }}
                    onMouseOver={() => {
                      this.setState({
                        iconInsta: insta,
                      });
                    }}
                    onMouseOut={() => {
                      this.setState({
                        iconInsta: instaDeactive,
                      });
                    }}
                    onClick={() => {
                      linkOpen(getInstagram());
                    }}
                  />
                  <img
                    src={this.state.iconYoutube}
                    style={{ cursor: 'pointer', height: 20, marginLeft: 10 }}
                    onMouseOver={() => {
                      this.setState({
                        iconYoutube: youtube,
                      });
                    }}
                    onMouseOut={() => {
                      this.setState({
                        iconYoutube: youtubeDeactive,
                      });
                    }}
                    onClick={() => {
                      linkOpen(getYoutube());
                    }}
                  />
                  <img
                    src={this.state.iconBlog}
                    style={{
                      height: 20,
                      marginLeft: 10,
                      marginRight: 10,
                      cursor: 'pointer',
                    }}
                    onMouseOver={() => {
                      this.setState({
                        iconBlog: blog,
                      });
                    }}
                    onMouseOut={() => {
                      this.setState({
                        iconBlog: blogDeactive,
                      });
                    }}
                    onClick={() => {
                      linkOpen(getBlog());
                    }}
                  />
                </div>
              </div>

              <div
                style={
                  Mobile()
                    ? { overflowY: 'hidden', height: 200 }
                    : { overflowY: 'hidden', height: 300 }
                }
              >
                <img src={`${headerImg}`} style={{ width: '100%' }} />
              </div>

              <div style={{ width: '90%', maxWidth: 500, marginBottom: 20 }}>
                <CardHeader
                  style={{
                    fontSize: 20,
                    textAlign: 'center',
                    fontWeight: 'bolder',
                    border: 0,
                  }}
                >
                  아이디 찾기
                </CardHeader>
                <CardBody>
                  <Form>
                    <FormGroup>
                      <Label
                        for="exampleEmail"
                        sm={12}
                        style={{ fontWeight: 'bold', fontSize: 16 }}
                      >
                        휴대폰번호로 찾기
                      </Label>
                    </FormGroup>
                    <FormGroup>
                      <Col sm={12}>
                        <Input
                          type="email"
                          name="id"
                          value={this.state.name}
                          onChange={text => {
                            this.setState({
                              name: text.target.value,
                            });
                          }}
                          placeholder="회원 이름 입력"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup>
                      <Col
                        sm={12}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Input
                          style={{ width: '65%' }}
                          type="tel"
                          name="tel"
                          value={this.state.contact}
                          onChange={text => {
                            this.setState({
                              contact: phoneNumber(text.target.value),
                            });
                          }}
                          placeholder="휴대폰번호 입력"
                        />
                        <Button
                          style={{ width: '32%' }}
                          outline
                          onClick={() => {
                            this.auth();
                          }}
                        >
                          인증번호 찾기
                        </Button>
                      </Col>
                    </FormGroup>

                    <FormGroup>
                      <Col sm={12}>
                        <Input
                          type="tel"
                          name="tel"
                          value={this.state.authNo}
                          onChange={text => {
                            this.setState({
                              authNo: text.target.value,
                            });
                          }}
                          disabled={this.state.authCheckBtn ? false : true}
                          placeholder="인증번호를 입력해주세요."
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup
                      style={{
                        width: '100%',
                        paddingLeft: '4%',
                        paddingRight: '4%',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Button
                        color={'primary'}
                        outline
                        onClick={() => {
                          this.props.history.push('/');
                        }}
                      >
                        돌아가기
                      </Button>
                      <Button
                        outline
                        disabled={this.state.authCheckBtn ? false : true}
                        onClick={() => {
                          this.authCheck();
                        }}
                      >
                        찾기
                      </Button>
                    </FormGroup>
                  </Form>
                </CardBody>
                <CardFooter
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingRight: '7%',
                    fontSize: 11,
                  }}
                >
                  <Col sm={6} style={{ paddingTop: 10 }}>
                    <div>(주)파이오닉</div>
                    <div>Tel. 070-8277-4577</div>
                    <div>평일 10:00 ~ 18:00 상담 가능</div>
                  </Col>

                  <Col sm={6} style={{ paddingTop: 10, textAlign: 'right' }}>
                    <div>사업자등록번호 : 452-86-02106</div>
                    <div>대표자:송승준</div>
                  </Col>
                </CardFooter>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default FindId;
