import React from 'react';
import { Row } from 'reactstrap';
import styled from 'styled-components';
import { RequestAxios } from '../utils/request';
import crypto from 'crypto';

import { encrypt } from '../utils/password';

import instaDeactive from '../assets/img/headericon/insta_deactive.png';

import youtubeDeactive from '../assets/img/headericon/youtube_deactive.png';

import blogDeactive from '../assets/img/headericon/blog_deactive.png';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

class AppAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sIv: '',
      sKey: '',
      token_version_id: '',
      enc_data: '',
      integrity_value: '',
      yackwanModal: false,
      yackwanTitle: '',
      yackwanDescription: '',
      agress_useterm: false,
      agress_policy: false,
      iconInsta: instaDeactive,
      iconYoutube: youtubeDeactive,
      iconBlog: blogDeactive,
    };
    this.decrypt = this.decrypt.bind(this);
    this.getNiceToken = this.getNiceToken.bind(this);
    this.getNiceSecretToken = this.getNiceSecretToken.bind(this);
    this.formSend = this.formSend.bind(this);
    this.encrypt = this.encrypt.bind(this);
  }

  componentDidMount() {
    this.getNiceToken();
  }

  getNiceToken = async () => {
    try {
      let method = 'GET';
      let url = 'user/getNiceToken';
      let data = new Map();

      const response = await RequestAxios(data, method, url);
      const jsonResponse = JSON.parse(response.data);

      if (
        jsonResponse.dataHeader.GW_RSLT_CD === '1200' &&
        jsonResponse.dataHeader.GW_RSLT_MSG === '오류 없음'
      ) {
        const getBody = jsonResponse.dataBody;

        if (getBody.access_token) {
          this.getNiceSecretToken(getBody.access_token);
        }
      } else {
        console.log('에러');
      }
    } catch (error) {
      console.log(String(error));
    }
  };

  encrypt = (data, key, iv) => {
    let cipher = crypto.createCipheriv('aes-128-cbc', key, iv);
    let encrypted = cipher.update(data, 'utf8', 'base64');
    encrypted += cipher.final('base64');
    return encrypted;
  };

  decrypt = data => {
    const key = this.state.sKey;
    const iv = this.state.sIv;

    let decipher = crypto.createDecipheriv('aes-128-cbc', key, iv);
    let decrypted = decipher.update(data, 'base64');
    decrypted += decipher.final();

    return decrypted;
  };

  getNiceSecretToken = async niceToken => {
    try {
      let method = 'GET';
      let url = `user/getNiceSecretToken?niceToken=${niceToken}`;
      let data = new Map();

      const response = await RequestAxios(data, method, url);
      const req_dtim = response.data.req_dtim;
      const req_no = response.data.req_no;

      const jsonResponse = JSON.parse(response.data.data);

      if (
        jsonResponse.dataHeader.GW_RSLT_CD === '1200' &&
        jsonResponse.dataHeader.GW_RSLT_MSG === '오류 없음'
      ) {
        const getBody = jsonResponse.dataBody;

        const token_version_id = getBody.token_version_id;
        const result = `${req_dtim.trim()}${req_no.trim()}${getBody.token_val.trim()}`;

        const resultVal = crypto
          .createHash('sha256')
          .update(result)
          .digest('base64');

        const key = resultVal.substr(0, 16);
        const iv = resultVal.substr(
          resultVal.length - 16,
          resultVal.length - 1,
        );
        const hmac_key = resultVal.substr(0, 32);

        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: 'authKey',
            data: {
              sIv: iv,
              sKey: key,
            },
          }),
        );

        this.setState({
          sIv: iv,
          sKey: key,
        });

        const returnUrl = `${window.location.origin}/appauthresult`;

        let plain_data = {
          requestno: req_no,
          returnurl: returnUrl,
          methodtype: 'GET',
          sitecode: getBody.site_code,
          authtype: 'M',
        };

        let plain = JSON.stringify(plain_data);
        let enc_data = this.encrypt(plain, key, iv);

        const hmac = crypto.createHmac('sha256', hmac_key);
        const integrity = hmac.update(enc_data).digest('base64');

        this.setState({
          token_version_id: token_version_id,
          enc_data: enc_data,
          integrity_value: integrity,
        });

        this.formSend();
      } else {
        console.log('에러');
      }
    } catch (error) {
      console.log(error);
    }
  };

  formSend = () => {
    const frm = document.createElement('form');
    frm.setAttribute('id', 'form');
    frm.setAttribute(
      'action',
      'https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb',
    );
    frm.setAttribute('method', 'post');

    let hiddenField = document.createElement('input');
    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', 'm');
    hiddenField.setAttribute('value', 'service');
    frm.appendChild(hiddenField);

    hiddenField = document.createElement('input');
    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', 'token_version_id');
    hiddenField.setAttribute('value', this.state.token_version_id);
    frm.appendChild(hiddenField);

    hiddenField = document.createElement('input');
    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', 'enc_data');
    hiddenField.setAttribute('value', this.state.enc_data);
    frm.appendChild(hiddenField);

    hiddenField = document.createElement('input');
    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', 'integrity_value');
    hiddenField.setAttribute('value', this.state.integrity_value);
    frm.appendChild(hiddenField);

    document.body.appendChild(frm);

    // window.open(
    //   'about:blank',
    //   'auth-form',
    //   'width=' +
    //     parseInt(window.innerWidth) * 0.5 +
    //     ',height=' +
    //     parseInt(window.innerHeight) * 0.5 +
    //     ',toolbar=0,menubar=0,location=0,status=0,scrollbars=1,resizable=0,left=0,top=0',
    // );
    frm.submit();

    let intervalCookie;

    intervalCookie = setInterval(() => {
      if (cookies.get('auth')) {
        const decryptText = encrypt(this.decrypt(cookies.get('auth')));
        cookies.set('auths', decryptText);
        clearInterval(intervalCookie);
        this.props.history.push('/registerauth');
      }
    }, 1000);
  };

  render() {
    return (
      <Row
        style={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      ></Row>
    );
  }
}

export default AppAuth;
