import { MdBorderAll, MdRemove } from 'react-icons/md';

export const adminMenuList = {
  single: [
    { to: 'device', name: '기기 관리', exact: false, Icon: MdBorderAll },
    { to: 'deviceinstall', name: '기기 설치', exact: false, Icon: MdBorderAll },
    { to: 'devicelist', name: '기기 리스트', exact: false, Icon: MdBorderAll },
    { to: 'requestdevice', name: '설치 요청', exact: false, Icon: MdBorderAll },
    { to: 'devicestatus', name: '기기 상태', exact: false, Icon: MdBorderAll },
    { to: 'collection', name: '수거 관리', exact: false, Icon: MdBorderAll },
    {
      to: 'collectionregister',
      name: '수거 등록',
      exact: false,
      Icon: MdBorderAll,
    },
    {
      to: 'collectionlist',
      name: '수거 리스트',
      exact: false,
      Icon: MdBorderAll,
    },
    { to: 'point', name: '포인트 내역', exact: false, Icon: MdBorderAll },
    {
      to: 'exchange',
      name: '환전 요청/지급 내역',
      exact: false,
      Icon: MdBorderAll,
    },
    {
      to: 'donation',
      name: '후원 요청/후원 내역',
      exact: false,
      Icon: MdBorderAll,
    },
    { to: 'update', name: '업데이트 관리', exact: false, Icon: MdBorderAll },
    { to: 'users', name: '사용자', exact: false, Icon: MdBorderAll },
  ],

  folder: [
    {
      to: 'customer',
      name: '고객 지원',
      list: [
        { to: '/notice', name: '공지 사항', exact: false, Icon: MdRemove },
        { to: '/story', name: '퓨닉 스토리', exact: false, Icon: MdRemove },
        { to: '/qna', name: '자주하는 질문', exact: false, Icon: MdRemove },
        { to: '/customer', name: '1:1 문의', exact: false, Icon: MdRemove },
      ],
    },
    {
      to: 'ad',
      name: '광고관리',
      list: [
        {
          to: '/adfile',
          name: '광고 컨텐츠 관리',
          exact: false,
          Icon: MdRemove,
        },
      ],
    },
  ],
};
