import React from 'react';
import {
  Card,
  CardFooter,
  CardBody,
  CardHeader,
  Button,
  Col,
  Modal,
  ModalBody,
  Row,
  Label,
  Input,
  Form,
  FormGroup,
} from 'reactstrap';
import { RequestAxios } from '../utils/request';
import Loader from 'react-spinners/RotateLoader';
import logoImg from '../assets/img/puniclogo.png';
import {
  ToastsStore,
  ToastsContainer,
  ToastsContainerPosition,
} from 'react-toasts';
import { css } from '@emotion/react';
import {
  pwdCheck,
  getInstagram,
  getYoutube,
  getBlog,
} from '../utils/Formcheck';
import insta from '../assets/img/headericon/insta.png';
import instaDeactive from '../assets/img/headericon/insta_deactive.png';
import youtube from '../assets/img/headericon/youtube.png';
import youtubeDeactive from '../assets/img/headericon/youtube_deactive.png';
import blog from '../assets/img/headericon/blog.png';
import blogDeactive from '../assets/img/headericon/blog_deactive.png';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const linkOpen = juso => {
  window.open(`${juso}`);
};

class ChangePwd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      contact: '',
      pwd: '',
      pwdRe: '',
      iconInsta: instaDeactive,
      iconYoutube: youtubeDeactive,
      iconBlog: blogDeactive,
    };

    this.pwdChnage = this.pwdChnage.bind(this);
  }

  componentDidMount() {
    if (!this.props.location.state) {
      this.props.history.push('/');
    } else {
      this.setState({
        contact: this.props.location.state.contact,
      });

      this.props.history.replace('/changepwd', null);
    }
  }

  pwdChnage = async () => {
    try {
      this.setState({
        loading: true,
      });
      let method = 'put';

      let data = new Map();

      if (this.state.contact) {
        data.set('contact', this.state.contact);
      } else {
        throw '연락처 정보가 없습니다.';
      }

      if (this.state.pwd) {
        if (pwdCheck(this.state.pwd)) {
          data.set('pwd', this.state.pwd);
        } else {
          throw '비밀번호는 영문 + 숫자 조합으로 6자리이상 입력해주세요';
        }
      } else {
        throw '비밀번호를 입력해주세요.';
      }

      if (this.state.pwd === this.state.pwdRe) {
        data.set('pwd', this.state.pwd);
      } else {
        throw '비밀번호와 비밀번호 확인이 일치하지 않습니다.';
      }

      let url = `user/changepwd`;

      const response = await RequestAxios(data, method, url);

      if (response !== 9999 || response !== 10000) {
        if (response.data.result === 0) {
          ToastsStore.success('비밀번호가 변경 되었습니다.');

          setTimeout(() => {
            this.props.history.push('/');
          }, 1000);
        } else if (response.data.result === 3) {
          throw '변경 실패';
        } else {
          throw '변경 실패';
        }
      } else {
        if (response === 10000) {
          throw '사용할수 없는 문자열이 포함되어 있습니다.';
        }
      }
    } catch (error) {
      ToastsStore.error(String(error));

      this.setState({
        loading: false,
      });
    }
  };

  render() {
    return (
      <Row
        style={{
          height: '85vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ToastsContainer
          position={ToastsContainerPosition.BOTTOM_RIGHT}
          store={ToastsStore}
          lightBackground
        />

        <Modal
          key={'loadingModal'}
          isOpen={this.state.loading}
          centered={true}
          className="loading-container"
          fade={false}
        >
          <ModalBody style={{ display: 'none' }}></ModalBody>
          <div className="overlay-box" width="100%">
            <Loader
              color={'#fff'}
              loading={this.state.loading}
              css={override}
              size={15}
              // height={400}
              // width={100}
              // radius={10}
              // margin={20}
              speedMultiplier={0.4}
            />
          </div>
        </Modal>

        <Col lg={4} md={6}>
          <Card>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  width: '90%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: 20,
                  marginBottom: 10,
                }}
              >
                <div></div>
                <img
                  src={`${logoImg}`}
                  style={{ height: 40, marginLeft: 80, cursor: 'pointer' }}
                  onClick={() => {
                    this.props.history.push('/');
                  }}
                />
                <div>
                  <img
                    src={this.state.iconInsta}
                    style={{ height: 20, cursor: 'pointer' }}
                    onMouseOver={() => {
                      this.setState({
                        iconInsta: insta,
                      });
                    }}
                    onMouseOut={() => {
                      this.setState({
                        iconInsta: instaDeactive,
                      });
                    }}
                    onClick={() => {
                      linkOpen(getInstagram());
                    }}
                  />
                  <img
                    src={this.state.iconYoutube}
                    style={{ cursor: 'pointer', height: 20, marginLeft: 10 }}
                    onMouseOver={() => {
                      this.setState({
                        iconYoutube: youtube,
                      });
                    }}
                    onMouseOut={() => {
                      this.setState({
                        iconYoutube: youtubeDeactive,
                      });
                    }}
                    onClick={() => {
                      linkOpen(getYoutube());
                    }}
                  />
                  <img
                    src={this.state.iconBlog}
                    style={{ height: 20, marginLeft: 10, cursor: 'pointer' }}
                    onMouseOver={() => {
                      this.setState({
                        iconBlog: blog,
                      });
                    }}
                    onMouseOut={() => {
                      this.setState({
                        iconBlog: blogDeactive,
                      });
                    }}
                    onClick={() => {
                      linkOpen(getBlog());
                    }}
                  />
                </div>
              </div>

              <div style={{ width: '90%', maxWidth: 500, marginBottom: 20 }}>
                <CardHeader style={{ fontSize: 20, textAlign: 'center' }}>
                  비밀번호 변경하기
                </CardHeader>
                <CardBody>
                  <Form>
                    <FormGroup>
                      <Label
                        for="examplePassword"
                        sm={12}
                        style={{ fontWeight: 'bold' }}
                      >
                        비밀번호 *
                      </Label>
                      <Col sm={12}>
                        <Input
                          type="password"
                          name="pwd"
                          autoComplete="new-password"
                          value={this.state.pwd}
                          onChange={text => {
                            this.setState({
                              pwd: text.target.value,
                            });
                          }}
                          placeholder="비밀번호 영문 + 숫자 포함 6자리이상 입력해주세요."
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        for="examplePassword"
                        sm={12}
                        style={{ fontWeight: 'bold' }}
                      >
                        비밀번호 확인 *
                      </Label>
                      <Col sm={12}>
                        <Input
                          type="password"
                          name="pwdRe"
                          value={this.state.pwdRe}
                          onChange={text => {
                            this.setState({
                              pwdRe: text.target.value,
                            });
                          }}
                          placeholder="입력하신 비밀번호와 동일하게 입력해주세요."
                        />
                      </Col>
                    </FormGroup>
                  </Form>
                </CardBody>
                <CardFooter
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Button
                    onClick={() => {
                      this.pwdChnage();
                    }}
                  >
                    변경하기
                  </Button>
                </CardFooter>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default ChangePwd;
