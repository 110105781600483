import React from 'react';

import { Navbar, Nav, NavItem, Col } from 'reactstrap';

const Footer = () => {
  return (
    <Navbar>
      <Nav navbar style={{ width: '100%' }}>
        <NavItem
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: 11,
          }}
        >
          <Col sm={6} style={{ paddingTop: 10 }}>
            <div>(주)파이오닉</div>
            <div>Tel. 070-8277-4577</div>
            <div>평일 10:00 ~ 18:00 상담 가능</div>
          </Col>

          <Col sm={6} style={{ paddingTop: 10, textAlign: 'right' }}>
            <div>사업자등록번호 : 452-86-02106</div>
            <div>대표자:송승준</div>
          </Col>
        </NavItem>
      </Nav>
    </Navbar>
  );
};

export default Footer;
