import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from 'reactstrap';

import { RequestMap } from '../utils/request';

class Address extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      searchAddressList: [],
      addressKeyword: '',
    };

    this.findMap = this.findMap.bind(this);
    this.checkSearchedWord = this.checkSearchedWord.bind(this);
  }

  findMap = async () => {
    // 적용예 (api 호출 전에 검색어 체크)
    // if (!this.checkSearchedWord(this.state.addressKeyword)) {
    //   return ;
    // }

    let data = new Map();
    data.set('confmKey', 'U01TX0FVVEgyMDIyMDcyOTE3MzkyNDExMjgzMzQ=');
    // data.set('confmKey',bdMgtSnbdMgtSn'U01TX0FVVEgyMDlyMCcyNjExMzc1MzExMjgxODU=');
    data.set('currentPage', '1');
    data.set('countPerPage', '10');
    data.set('resultType', 'json');
    data.set('useDetailAddr', 'Y');
    data.set('keyword', this.state.addressKeyword);

    const response = await RequestMap(data);

    if (response.status === 200 && response.statusText === 'OK') {
      // console.log(response.data);
      let juso = response.data
        .substring(0, response.data.length - 1)
        .substring(1);

      juso = JSON.parse(juso);

      this.setState({
        searchAddressList: juso.results.juso,
      });
    }
  };

  checkSearchedWord(obj) {
    if (obj.value.length > 0) {
      //특수문자 제거
      var expText = /[%=><]/;
      if (expText.test(obj.value) == true) {
        alert('특수문자를 입력 할수 없습니다.');
        obj.value = obj.value.split(expText).join('');
        return false;
      }

      //특정문자열(sql예약어의 앞뒤공백포함) 제거
      var sqlArray = new Array(
        //sql 예약어
        'OR',
        'SELECT',
        'INSERT',
        'DELETE',
        'UPDATE',
        'CREATE',
        'DROP',
        'EXEC',
        'UNION',
        'FETCH',
        'DECLARE',
        'TRUNCATE',
      );

      var regex;
      for (var i = 0; i < sqlArray.length; i++) {
        regex = new RegExp(sqlArray[i], 'gi');

        if (regex.test(obj.value)) {
          alert(
            '"' + sqlArray[i] + '"와(과) 같은 특정문자로 검색할 수 없습니다.',
          );
          obj.value = obj.value.replace(regex, '');
          return false;
        }
      }
    }
    return true;
  }

  render() {
    return (
      <Modal
        isOpen={this.props.open}
        centered={true}
        className="loading-container"
        fade={false}
      >
        <ModalHeader style={{ backgroundColor: '#eee', borderBottomWidth: 0 }}>
          주소 검색
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              width: '100%',
              flex: 1,
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <span
              style={{
                flex: 0.7,
                backgroundColor: '#eee',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Input
                name="keyword"
                onChange={text => {
                  this.setState({
                    addressKeyword: text.target.value,
                  });
                }}
                onKeyPress={e => {
                  if (e.key === 'Enter') this.findMap();
                }}
              />
            </span>

            <span
              style={{
                flex: 0.3,
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                padding: 5,
              }}
            >
              <Button
                color="primary"
                style={{ width: '100%' }}
                outline
                onClick={() => {
                  this.findMap();
                }}
              >
                검색
              </Button>
            </span>
          </div>
          {this.state.searchAddressList &&
          this.state.searchAddressList.length > 0 ? (
            <div
              style={{ width: '100%', height: 200, overflowY: 'auto' }}
              className="modalTable"
            >
              {this.state.searchAddressList.map(v => {
                return (
                  <div
                    key={v.bdMgtSn}
                    onClick={() => {
                      this.props.selectAddress(v);
                    }}
                    style={{
                      paddingTop: 10,
                      paddingBottom: 10,
                      borderBottom: 1,
                      borderBottomStyle: 'solid',
                      borderBottomColor: '#eee',
                      cursor: 'pointer',
                    }}
                  >
                    {`${v.roadAddr} ( ${v.jibunAddr} )`}
                  </div>
                );
              })}
            </div>
          ) : (
            <></>
          )}
        </ModalBody>
        <ModalFooter
          centered="true"
          className="justify-content-center"
          style={{ borderTopWidth: 0 }}
        >
          <Button
            color="secondary"
            onClick={() => {
              this.props.close(false);
            }}
          >
            취소
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default Address;
