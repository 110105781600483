import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import styled from 'styled-components';
import crypto from 'crypto';
import logoImg from '../assets/img/puniclogo.png';

import instaDeactive from '../assets/img/headericon/insta_deactive.png';
import youtubeDeactive from '../assets/img/headericon/youtube_deactive.png';

import blogDeactive from '../assets/img/headericon/blog_deactive.png';

import { checkMobile } from '../utils/Formcheck';

function getParameterByName(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
    results = regex.exec(window.location.search);
  return results == null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

class AppAuthResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sIv: '',
      sKey: '',
      token_version_id: '',
      enc_data: '',
      integrity_value: '',
      yackwanModal: false,
      yackwanTitle: '',
      yackwanDescription: '',
      agress_useterm: false,
      agress_policy: false,
      iconInsta: instaDeactive,
      iconYoutube: youtubeDeactive,
      iconBlog: blogDeactive,
      message: '',
    };
  }

  decrypt = data => {
    const key = this.state.sKey;
    const iv = this.state.sIv;

    let decipher = crypto.createDecipheriv('aes-128-cbc', key, iv);
    let decrypted = decipher.update(data, 'base64');
    decrypted += decipher.final();

    return decrypted;
  };

  componentDidMount = () => {
    if (checkMobile() == 'android') {
      document.addEventListener('message', e => {
        if (e.data) {
          const getData = JSON.parse(e.data);
          if (getData.type == 'appKey') {
            this.setState({
              sIv: getData.data.sIv,
              sKey: getData.data.sKey,
            });

            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                type: 'authAccessInfo',
                userInfo: this.decrypt(getParameterByName('enc_data')),
              }),
            );
          }
        }
      });
    } else {
      window.addEventListener('message', e => {
        if (e.data) {
          const getData = JSON.parse(e.data);
          if (getData.type == 'appKey') {
            this.setState({
              sIv: getData.data.sIv,
              sKey: getData.data.sKey,
            });

            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                type: 'authAccessInfo',
                userInfo: this.decrypt(getParameterByName('enc_data')),
              }),
            );
          }
        }
      });
    }

    window.ReactNativeWebView.postMessage(
      JSON.stringify({ type: 'authKeyRequest' }),
    );
  };

  render() {
    return (
      <Row
        style={{
          height: '85vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Col md={6} lg={4}>
          <Card body>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  width: '98%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <div></div>
                <img
                  src={`${logoImg}`}
                  style={{ height: 40, marginLeft: 80, cursor: 'pointer' }}
                  onClick={() => {
                    this.props.history.push('/');
                  }}
                />
                <div></div>
              </div>
              <div
                style={{
                  marginTop: 30,
                  marginBottom: 30,
                  padding: 20,
                  display: 'flex',
                  justifyContent: 'center',
                  whiteSpace: 'pre-wrap',
                  fontSize: 16,
                }}
              >
                본인인증이 완료 되었습니다.
                {this.state.message}
                <br />
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default AppAuthResult;
